import React, {Component} from "react"
import styles from "./prototype-card.module.css"
import {observer} from "mobx-react"
import {ReactComponent as ChartIcon} from "./parts/images/bar-chart.svg"
import {ReactComponent as ClockIcon} from "./parts/images/clock.svg"
import {TrashButton} from "../../../../components/buttons/trash-btn"
import {EditButton} from "./parts/edit-button"
import {api_response} from "lib/gate/interfaces"

interface Props {
    item: api_response.Prototype
    onEdit: () => void
    onDelete: () => void
}

@observer
export class PrototypeCard extends Component<Props, {}> {
    public render() {
        const {onEdit, onDelete, item} = this.props
        return (
            <article className={styles.card}>
                <header className={styles.imageWrapper}>
                    <img src={require("./parts/images/goodgradesar.jpg")} alt="" className={styles.image} />
                </header>
                <section className={styles.info}>
                    <div className={styles.infoBar}>
                        <div className={styles.numberWrapper}>
                            <span className={styles.number}>{item.id}</span>
                        </div>
                        <div className={styles.infoItem}>
                            <ClockIcon className={styles.infoIcon} width="11" height="11" />
                            <p className={styles.infoData}>{item.min_hours_attended} Hours</p>
                        </div>
                        <div className={styles.infoItem}>
                            <ChartIcon className={styles.infoIcon} width="11" height="11" />
                            <p className={styles.infoData}>Active</p>
                        </div>
                    </div>
                    <h2 className={styles.title}>{item.name}</h2>
                    <p className={styles.description}>{item.description}</p>
                </section>
                <footer className={styles.footer}>
                    <EditButton onClick={() => onEdit()} />
                    <TrashButton onClick={() => onDelete()} />
                </footer>
            </article>
        )
    }
}
