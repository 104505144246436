import React, {Component} from "react"
import Select from "react-select"
import {api_response} from "lib/gate/interfaces"
import {observer} from "mobx-react"
import {computed} from "mobx"

interface Props {
    selected_statuses: api_response.MilestoneStatus[]
    onChange: (statuses: api_response.MilestoneStatus[]) => void
}

const options: Item[] = [
    {value: "unreached", label: "Unreached"},
    {value: "in progress", label: "In Progress"},
    {value: "overdue", label: "Overdue"},
    {value: "completed", label: "Completed"}
]

interface Item {
    value
    label: string
}

@observer
export class PassportStatusesSelect extends Component<Props, {}> {
    @computed
    private get selectedItems(): Item[] {
        let arr: Item[] = []
        for (let status of this.props.selected_statuses) {
            for (let el of options) {
                if (el.value === status) {
                    arr.push(el)
                    break
                }
            }
        }

        return arr
    }

    public render() {
        const {onChange} = this.props
        return (
            <Select
                isMulti
                options={options}
                value={this.selectedItems}
                onChange={(items: Item[]) => {
                    if (!items) {
                        onChange([])
                        return
                    }

                    const arr = items.map((item) => {
                        return item.value
                    })
                    onChange(arr)
                }}
                placeholder={"Passport status"}
            />
        )
    }
}
