import React, {Component} from "react"
import styles from "./DashboardCard.module.css"
import {api_response} from "lib/gate/interfaces"
import {Link} from "react-router-dom"

interface Props {
    item: api_response.DashboardItem
}

export class DashboardCard extends Component<Props, {}> {
    public render() {
        const item = this.props.item
        return (
            <article className={styles.card}>
                <header className={styles.header}>
                    <p className={styles.statistic}>{item.total_students} Students 82% On track</p>
                    <Link className={styles.title} to="/klasscards/students">
                        {item.location_name}
                    </Link>
                </header>
                <table className={styles.table}>
                    <tbody>
                        {item.majors.map((el) => {
                            return (
                                <tr>
                                    <td className={styles.cellName}>{el.major_name}</td>
                                    <td className={styles.cellParam}>{el.count}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </article>
        )
    }
}
