import React, {Component} from "react"
import styles from "./text-area.module.css"

interface Props {
    value: string
    onChange: (value: string) => void
    disabled?: boolean
    placeholder?: string
}

export class TextArea extends Component<Props, {}> {
    private onChange(event) {
        this.props.onChange(event.target.value)
    }

    public render() {
        const {placeholder, disabled, value} = this.props
        return (
            <textarea
                className={styles.input}
                value={value}
                disabled={disabled}
                onChange={this.onChange.bind(this)}
                placeholder={placeholder}></textarea>
        )
    }
}
