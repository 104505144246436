import {Component} from "react"

interface Prop {
    id: string
    header: JSX.Element
    cell: JSX.Element
}

export class Column extends Component<Prop> {
    public render() {
        return null
    }
}
