import React, {Component} from "react"
import {observer} from "mobx-react"
import classNames from "classnames"
import styles from "./navigation-tab.module.css"
import {ReactComponent as IconFill} from "./parts/icons/fill.svg"
import {ReactComponent as IconUniversity} from "./parts/icons/university.svg"
import {ReactComponent as IconUser} from "./parts/icons/user.svg"
import {ReactComponent as IconWorkspace} from "./parts/icons/workspace.svg"
import {ReactComponent as IconSettings} from "./parts/icons/settings.svg"
import {Link} from "react-router-dom"

interface Props {
    activeTabKey: TabKey
    token: string
}

const KEYS: TabKey[] = ["dashboard", "workspace", "students", "prototypes"]

type TabKey = "dashboard" | "students" | "prototypes" | "settings" | "workspace"

@observer
export class NavigationTab extends Component<Props, {}> {
    public render() {
        const {token, activeTabKey} = this.props

        return (
            <nav className={styles.wrapper}>
                {KEYS.map((key: TabKey) => {
                    let title
                    if (key === "prototypes") {
                        title = "Milestones Setup"
                    }
                    return <Element id={key} token={token} activeTabKey={activeTabKey} key={key} title={title} />
                })}
            </nav>
        )
    }
}

interface ElementProps {
    id: TabKey
    token: string
    activeTabKey: TabKey
    title?: string
}
function Element({id, token, activeTabKey, title}: ElementProps) {
    let isActive = false
    if (id === activeTabKey) {
        isActive = true
    }

    if (!title) {
        title = id
    }
    return (
        <Link to={`/${id}?token=${token}`} className={classNames(styles.link, isActive && styles.active)}>
            <Icon id={id} />
            <span className={styles.text}>{title}</span>
        </Link>
    )
}

function Icon({id}: {id: TabKey}) {
    switch (id) {
        case "students":
            return <IconUser width="24" height="24" className={styles.icon} />
        case "dashboard":
            return <IconUniversity width="24" height="24" className={styles.icon} />
        case "prototypes":
            return <IconFill width="24" height="24" className={styles.icon} />
        case "workspace":
            return <IconWorkspace width="24" height="24" className={styles.icon} />
        case "settings":
            return <IconSettings width="24" height="24" className={styles.icon} />
        default:
            return null
    }
}
