import React, {Component} from "react"
import styles from "./icons.module.css"
import {ReactComponent as CheckmarkIcon} from "./checkmark-circle.svg"
import {ReactComponent as EmptyIcon} from "./circle-empty.svg"
import {ReactComponent as WarningIcon} from "./warning-triangle.svg"
import {ReactComponent as ExclamationIcon} from "./exclamation-circle.svg"
import {api_response} from "lib/gate/interfaces"

interface Props {
    name: api_response.MilestoneStatus
}

export class Icons extends Component<Props, {}> {
    public render() {
        const {name} = this.props

        return <React.Fragment>{getIcons(name)}</React.Fragment>
    }
}

function getIcons(name: api_response.MilestoneStatus) {
    switch (name) {
        case "completed":
            return <CheckmarkIcon className={styles.icon} width={18} height={18} />
        case "in progress":
            return <ExclamationIcon className={styles.icon} width={18} height={18} />
        case "overdue":
            return <WarningIcon className={styles.icon} width={18} height={18} />
        case "unreached":
            return <EmptyIcon className={styles.icon} width={18} height={18} />

        default:
            return <EmptyIcon className={styles.icon} width={18} height={18} />
    }
}
