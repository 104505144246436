import React from "react"

interface Props {
    title: string
}

export default class HTMLTitle extends React.Component<Props, {}> {
    private title

    constructor(props) {
        super(props)
        this.title = "KlassApp"
    }

    componentDidMount() {
        if (this.props.title) {
            this.setTitle(this.props.title)
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.title !== this.props.title) {
            this.setTitle(nextProps.title)
        }
    }

    componentWillUnmount() {
        this.setTitle(this.title)
    }

    setTitle(title) {
        document.title = title
    }

    render() {
        return null
    }
}
