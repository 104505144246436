import React, {Component} from "react"

import styles from "./filter.module.css"
import Select from "react-select"
import {Button} from "./parts/button"
import {StringButton} from "components/buttons/string-button/string-button"

export class Filter extends Component<{}, {}> {
    public render() {
        const options = [
            {value: "chocolate", label: "Chocolate"},
            {value: "strawberry", label: "Strawberry"},
            {value: "vanilla", label: "Vanilla"}
        ]
        return (
            <div className={styles.filter}>
                <div className={styles.selectWrapper}>
                    <Select options={options} onChange={() => {}} placeholder={"Program"} />
                </div>
                <div className={styles.selectWrapper}>
                    <Select options={options} onChange={() => {}} placeholder={"Campus"} />
                </div>
                <div className={styles.selectWrapper}>
                    <Select options={options} onChange={() => {}} placeholder={"Milestone status"} />
                </div>
                <div className={styles.selectWrapper}>
                    <Select options={options} onChange={() => {}} placeholder={"Awaiting staff sign"} />
                </div>
                <StringButton onClick={() => {}} name={"Reset"} />
                <Button onClick={() => {}} name={"EXPORT"} />
            </div>
        )
    }
}
