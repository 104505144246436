import React from "react"
import styles from "./FormField.module.css"

interface Props {
    label?: string
    errorMessage?: string
    required?: boolean
    children: JSX.Element
}
export function FormField(props: Props) {
    const {label, errorMessage, required, children} = props

    return (
        <React.Fragment>
            <div className={styles.wrapper}>
                {label && (
                    <p className={styles.label}>
                        {label}
                        {required && <span className={styles.isRequired}>*</span>}
                    </p>
                )}

                {children}
                {errorMessage && <p className={styles.error}>{errorMessage}</p>}
            </div>
        </React.Fragment>
    )
}
