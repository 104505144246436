import React from "react"
import styles from "./FormLabel.module.css"

interface Props {
    label?: string
    children: JSX.Element
}
export function FormLabel(props: Props) {
    const {label, children} = props

    return (
        <React.Fragment>
            <label className={styles.wrapper}>
                <p className={styles.label}>{label}</p>
                {children}
            </label>
        </React.Fragment>
    )
}
