import React, {Component} from "react"
import styles from "./open-button.module.css"
import classNames from "classnames"

interface Props {
    isOpen: boolean
    disabled?: boolean
    onClick: () => void
}

export class OpenButton extends Component<Props, {}> {
    public render() {
        const {disabled, isOpen, onClick} = this.props
        return (
            <button className={styles.button} onClick={onClick} disabled={disabled}>
                <div className={classNames(styles.icon, isOpen && styles.iconOpen)}></div>
            </button>
        )
    }
}
