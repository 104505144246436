import React, {Component} from "react"
import styles from "./Signatures.module.css"
import {api_response, Role} from "lib/gate/interfaces"
import {Model} from "Model"
import {Gate} from "lib/gate/Gate"
import {SignButton} from "../sign-button"
import {observable} from "mobx"
import {observer} from "mobx-react"

interface Props {
    item: api_response.Milestone
    onClick: (item: api_response.Milestone) => void
    model: Model
    gate: Gate
}

@observer
export class Signatures extends Component<Props, {}> {
    @observable
    private studentSignatureSrc: string

    @observable
    private staffSignatureSrc: string

    public async componentDidUpdate() {
        this.loadSignatures()
    }

    public async componentDidMount() {
        this.loadSignatures()
    }

    public render() {
        const {item} = this.props
        const role = this.props.model.visitor.role

        return (
            <div className={styles.main}>
                <div className={styles.wrapper}>
                    <p className={styles.title}>STUDENT</p>
                    {this.getStudentSignature(this.studentSignatureSrc, item.student_signature_timestamp, role)}
                </div>
                <div className={styles.wrapper}>
                    <p className={styles.title}>STAFF</p>
                    {this.getStaffSignature(this.staffSignatureSrc, item.staff_signature_timestamp, role)}
                </div>
            </div>
        )
    }

    private async loadSignatures() {
        const m = this.props.item

        if (m.staff_signature_id) {
            this.staffSignatureSrc = await this.loadSignature(m.staff_signature_id)
        }

        if (m.student_signature_id) {
            this.studentSignatureSrc = await this.loadSignature(m.student_signature_id)
        }
    }

    private getStudentSignature(src: string, timestamp: string, role: Role): JSX.Element {
        let html
        if (src) {
            html = (
                <div className={styles.signatureWrapper}>
                    <img className={styles.signature} src={src} alt="" />
                </div>
            )
        } else {
            if (role === "student") {
                html = (
                    <SignButton
                        onClick={() => {
                            this.props.onClick(this.props.item)
                        }}
                    />
                )
            } else {
                html = <div className={styles.signatureWrapper}></div>
            }
        }

        return html
    }

    private getStaffSignature(src: string, timestamp: string, role: Role): JSX.Element {
        let html
        if (src) {
            html = (
                <div className={styles.signatureWrapper}>
                    <img className={styles.signature} src={src} alt="" />
                </div>
            )
        } else {
            if (role === "staff" || role === "admin") {
                html = (
                    <SignButton
                        onClick={() => {
                            this.props.onClick(this.props.item)
                        }}
                    />
                )
            } else {
                html = <div className={styles.signatureWrapper}></div>
            }
        }

        return html
    }

    private async loadSignature(id: number): Promise<string> {
        const signatures = this.props.model.signatures
        if (!signatures[id]) {
            let r = await this.props.gate.request(`/signatures/${id}/get`)
            signatures[id] = r.data
        }

        return signatures[id].json_data.img
    }

    private async onSign() {
        await this.props.gate.request(`/milestones/${this.props.item.id}/sign`)
        this.props.model.loadPassport(this.props.model.user.id)
    }
}
