import React, {Component} from "react"
import styles from "./page.module.css"
import HTMLTitle from "../HTMLTitle"

interface Props {
    htmlTitle?: string
}
export class Page extends Component<Props> {
    render() {
        return (
            <div className={styles.pageWrapper}>
                <HTMLTitle title={this.props.htmlTitle} />
                {this.props.children}
            </div>
        )
    }
}
