import React, {Component} from "react"
import styles from "./MilestonesPage.module.css"
import {Model} from "Model"
import {observer} from "mobx-react"
import {observable, toJS} from "mobx"
import {VisitorBar} from "components/VisitorBar"
import {Gate} from "lib/gate/Gate"
import {List} from "lib/gate/List"
import {IList} from "lib/gate/IList"
import {api_response, Filter} from "lib/gate/interfaces"
import {PrototypePopup} from "./parts/prototype-popup"
import {NavigationTab} from "components/navigation-tab"
import {Page} from "components/misc/page"
import {H1} from "components/misc/h1"
import {Search} from "components/search"
import {PrototypeCard} from "./parts/prototype-card"
import {AddButton} from "./parts/add-button"

interface Props {
    model: Model
    gate: Gate
}

@observer
export class MilestonesPage extends Component<Props, {}> {
    @observable
    private popup = {visible: false, itemId: null}

    @observable
    private list: IList<Filter, api_response.Prototype>

    constructor(props) {
        super(props)
        this.list = new List(this.props.gate, "/prototypes")
        this.list.setPageSize(100)
        this.list.setSort({
            orderBy: "id",
            order: "ASC"
        })
    }

    public async componentDidMount() {
        this.list.load()
    }

    public render() {
        const {model, gate} = this.props

        return (
            <Page htmlTitle="Milestones Setup">
                {this.popup.visible && (
                    <PrototypePopup
                        gate={gate}
                        model={model}
                        itemId={this.popup.itemId}
                        onClose={() => {
                            this.popup.visible = false
                            this.popup.itemId = null
                            this.list.load()
                        }}
                    />
                )}
                <VisitorBar model={model} gate={gate} />
                <header className={styles.header}>
                    <H1>Milestones</H1>
                    <Search placeholder="Enter Milestone name" onChange={() => {}} />
                </header>
                <main>
                    <div className={styles.navigationWrapper}>
                        <NavigationTab token={gate.getToken()} activeTabKey="prototypes" />
                    </div>
                    <section className={styles.cardWrapper}>
                        {this.list.items.map((item: api_response.Prototype) => {
                            return (
                                <PrototypeCard
                                    item={item}
                                    key={item.id}
                                    onEdit={() => {
                                        this.popup.visible = true
                                        this.popup.itemId = item.id
                                    }}
                                    onDelete={async () => {
                                        await gate.request(`/prototypes/${item.id}/delete`)
                                        this.list.load()
                                    }}
                                />
                            )
                        })}
                        <AddButton
                            onClick={() => {
                                this.popup.visible = true
                                this.popup.itemId = null
                            }}
                        />
                    </section>
                </main>
            </Page>
        )
    }

    private onFilter(filter: Filter) {
        console.log(toJS(filter))
        this.list.setFilter(filter)
        this.list.setPage(1)
        this.list.load()
    }
}
