import React, {Component} from "react"
import styles from "./input.module.css"

interface Props {
    placeholder?: string
    onChange: (value: string) => void
    value: string
    disabled?: boolean
}

export class Input extends Component<Props, {}> {
    private onChange(event) {
        this.props.onChange(event.target.value)
    }

    public render() {
        const {placeholder, disabled, value} = this.props
        return (
            <input
                className={styles.input}
                type="text"
                value={value}
                onChange={this.onChange.bind(this)}
                placeholder={placeholder}
                disabled={disabled}
            />
        )
    }
}
