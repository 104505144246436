import React, {Component} from "react"
import styles from "./search.module.css"
import {ReactComponent as Icon} from "./search.svg"
import {observer} from "mobx-react"
import {computed, observable} from "mobx"

interface Props {
    label?: string
    placeholder?: string
    onChange: (value: string) => void
}

@observer
export class Search extends Component<Props, {}> {
    @observable
    private value: string = ""

    @computed
    private get buttonDisabled(): boolean {
        if (this.value === "") {
            // return true
        }

        return false
    }

    private onSubmit(e) {
        e.preventDefault()
        e.stopPropagation()
        if (this.value !== "") {
            this.props.onChange(this.value)
        }
    }

    private onChange(event) {
        this.value = event.target.value
    }

    componentWillUnmount() {
        this.value = ""
    }

    public render() {
        const {placeholder, onChange} = this.props
        return (
            <div className={styles.wrapper}>
                <input
                    className={styles.input}
                    type="text"
                    value={this.value}
                    onChange={this.onChange.bind(this)}
                    placeholder={placeholder}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            onChange(this.value)
                        }
                    }}
                />
                <button
                    className={styles.button}
                    disabled={this.buttonDisabled}
                    onClick={() => {
                        onChange(this.value)
                    }}>
                    <Icon width="24" height="24" className={styles.icon} />
                </button>
            </div>
        )
    }
}
