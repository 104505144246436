import React, {Component} from "react"
import styles from "./header-button.module.css"
import classNames from "classnames"

export type ArrowType = "ASC" | "DESC"

interface Props {
    title?: string
    disabled?: boolean
    type?: ArrowType
    onClick: (type: ArrowType) => void
}

export class HeaderButton extends Component<Props, {}> {
    private onClick(event) {
        let newType: ArrowType
        if (this.props.type === "ASC") {
            newType = "DESC"
        }

        if (this.props.type === "DESC") {
            newType = "ASC"
        }

        this.props.onClick(newType)
    }

    public render() {
        const {title, disabled, type} = this.props
        return (
            <button className={styles.button} onClick={this.onClick.bind(this)} disabled={disabled}>
                <span className={styles.text}>{title}</span>
                {type && <div className={classNames(styles.arrow, type === "ASC" && styles.arrowUp)} />}
            </button>
        )
    }
}
