import React, {Component} from "react"
import styles from "./CustomCell.module.css"

interface Props {
    item?
}
export class CustomCell extends Component<Props> {
    public render() {
        const value = this.props.item["first_name"]
        const isSteve: boolean = value === "Steve"
        // let cssClass = `${styles.td}`;
        let cssClass = `table__cell`
        if (isSteve) {
            cssClass += ` ${styles.greenBackground}`
        }

        return (
            <td className={cssClass}>
                <div className="table__cell-wrapper">{value}</div>
            </td>
        )
    }
}
