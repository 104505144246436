import React, {Component} from "react"
import {observer} from "mobx-react"
import {action, observable, runInAction} from "mobx"
import {Gate} from "lib/gate/Gate"

interface Props {
    gate: Gate
}

interface StatusElement {
    id: number
    dashboard: boolean
    main: boolean
    label: string
}

@observer
export class StudentStatuses extends Component<Props, {}> {
    @observable
    private statuses: {[key: number]: StatusElement} = {}

    @action
    public async componentDidMount() {
        const [r1, r2] = await Promise.all([
            this.props.gate.request("/app-settings/get"),
            this.props.gate.request("/student-statuses/get-all")
        ])

        runInAction(() => {
            if (r2.success) {
                for (let s of r2.data) {
                    this.statuses[s.id] = {id: s.id, label: s.name, dashboard: false, main: false}
                }
            }

            if (r1.success && r1.data.json_data.statuses) {
                const dashboard: number[] = r1.data.json_data.statuses.dashboard || []
                const main: number[] = r1.data.json_data.statuses.main || []
                for (let id of dashboard) {
                    this.statuses[id] = {...this.statuses[id], dashboard: true}
                }
                for (let id of main) {
                    this.statuses[id] = {...this.statuses[id], main: true}
                }
            }
        })
    }

    public render() {
        return (
            <table className="student-statuses">
                <tbody>
                    <tr>
                        <th>Enabled for Milestones</th>
                        <th>Enabled for Dashboard</th>
                        <th>Status Name</th>
                    </tr>
                    {Object.values(this.statuses).map((el: StatusElement) => {
                        return this.getRow(el)
                    })}
                </tbody>
            </table>
        )
    }

    private getRow(el: StatusElement) {
        return (
            <tr>
                <td>{this.getCheckbox(`${el.id}_main`, el.main)}</td>
                <td>{this.getCheckbox(`${el.id}_dashboard`, el.dashboard)}</td>
                <td>{el.label}</td>
            </tr>
        )
    }

    private getCheckbox(id: string, checked: boolean) {
        return <input type="checkbox" id={id} onChange={this.onCheckbox.bind(this)} checked={checked} />
    }

    private async onCheckbox(event) {
        const id: number = parseInt(event.target.id.split("_")[0])
        const type: "main" | "dashboard" = event.target.id.split("_")[1]
        this.statuses[id] = {...this.statuses[id], [type]: event.target.checked}

        let main: number[] = []
        let dashboard: number[] = []

        for (let key in this.statuses) {
            let el: StatusElement = this.statuses[key]
            if (el.dashboard) {
                dashboard.push(el.id)
            }
            if (el.main) {
                main.push(el.id)
            }
        }

        const data = {
            json_data: {statuses: {main, dashboard}}
        }
        this.props.gate.request("/app-settings/edit", {data})
    }
}
