import React from "react"
import {Gate} from "lib/gate/Gate"
import {StudentsFilter} from "lib/gate/interfaces"
import {observable} from "mobx"
import {observer} from "mobx-react"
import {CampusesSelect} from "components/selects/CampusesSelect"
import {ProgramsSelect} from "components/selects/ProgramsSelect"
import styles from "./filer.module.css"
import Select from "react-select"
import {StringButton} from "components/buttons/string-button"
import {PassportStatusesSelect} from "./parts/PasportStatusesSelect"

interface Props {
    gate: Gate
    onChange: (filter: StudentsFilter) => void
}

@observer
export class Filter extends React.Component<Props, {}> {
    private readonly defaultFilter: StudentsFilter = {
        campus_ids: [],
        program_ids: [],
        prototype_ids: [],
        statuses: [],
        text: "",
        passport_statuses: []
    }

    @observable
    private filter: StudentsFilter = this.defaultFilter

    private setFilter(filter: StudentsFilter) {
        this.filter = filter
        this.props.onChange(this.filter)
    }

    public render() {
        const {gate} = this.props
        const filter = this.filter
        const options = [
            {value: "chocolate", label: "Chocolate"},
            {value: "strawberry", label: "Strawberry"},
            {value: "vanilla", label: "Vanilla"}
        ]

        return (
            <div className={styles.wrapper}>
                <div className={styles.selectWrapper}>
                    <CampusesSelect
                        gate={gate}
                        selected_ids={filter.campus_ids}
                        onChange={(ids) => {
                            console.log("CAMPUSES")
                            console.log(ids)
                            this.setFilter({...filter, campus_ids: ids})
                        }}
                    />
                </div>
                <div className={styles.selectWrapper}>
                    <ProgramsSelect
                        gate={gate}
                        campus_ids={filter.campus_ids}
                        selected_ids={filter.program_ids}
                        onChange={(ids) => {
                            this.setFilter({...filter, program_ids: ids})
                        }}
                    />
                </div>
                {/* <div className={styles.selectWrapper}>
                    <input
                        type="text"
                        value={filter.text}
                        onChange={(event) => {
                            this.setFilter({...filter, text: event.target.value})
                        }}
                    />
                </div> */}
                <div className={styles.selectWrapper}>
                    <PassportStatusesSelect
                        selected_statuses={this.filter.passport_statuses}
                        onChange={(statuses) => {
                            this.setFilter({...filter, passport_statuses: statuses})
                        }}
                    />
                </div>
                <div className={styles.selectWrapper}>
                    <Select options={options} onChange={() => {}} placeholder={"Awaiting staff sign"} />
                </div>
                <div className={styles.ButtonWrapper}>
                    <StringButton
                        onClick={() => {
                            this.setFilter(this.defaultFilter)
                        }}
                        name={"Reset Filters"}
                    />
                </div>

                {/*<div className={styles.selectWrapper}>
                    <Select options={options} onChange={() => {}} placeholder={"Columns"} />
                </div>*/}
            </div>
        )
    }
}
