import React, {Component} from "react"
import {SelectionModel} from "./SelectionModel"
import {MiddleTable} from "./MiddleTable"
import {IList} from "../../../../lib/gate/IList"
import {IListItem} from "../../../../lib/gate/interfaces"

interface Props {
    list: IList
    children: JSX.Element[]
    localStorageId?: string
    onRowClick: (item: IListItem) => void
    showSelectCheckbox?: boolean
    selectedItems?: SelectionModel
}

export class Table extends Component<Props> {
    public render() {
        return (
            <MiddleTable
                list={this.props.list}
                localStorageId={this.props.localStorageId}
                onRowClick={this.props.onRowClick}
                selectionModel={this.props.selectedItems}>
                {this.props.children}
            </MiddleTable>
        )
    }
}
