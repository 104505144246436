import React, {Component} from "react"
import styles from "./signature.module.css"
import SignatureCanvas from "react-signature-canvas"
import {action, observable} from "mobx"
import {observer} from "mobx-react"

import {Button} from "./parts/button"
import {ReactComponent as Icon} from "./parts/images/line.svg"
import {Model} from "Model"
import {Gate} from "lib/gate/Gate"

interface Props {
    model: Model
    gate: Gate
}

type Screen = "canvas" | "image" | "no signature"

@observer
export class Signature extends Component<Props, {}> {
    private mode = "signature"

    @observable
    private imgData: string | undefined

    @observable
    private screen: Screen = "no signature"

    @observable
    private clearEnabled: boolean = true

    @observable
    private saveEnabled: boolean = true

    private sigCanvas: typeof SignatureCanvas

    private get content(): any {
        switch (this.screen) {
            case "no signature":
                return (
                    <NoSignatureBox
                        onClick={() => {
                            this.setScreen("canvas")
                        }}
                    />
                )
            case "image":
                return <img alt="signature" src={this.imgData} className={styles.signaturePad} />
            case "canvas":
                return (
                    <SignatureCanvas
                        penColor="black"
                        canvasProps={{width: 407, height: 144, className: styles.currentSignature}}
                        ref={(ref) => {
                            this.sigCanvas = ref
                        }}
                    />
                )
            default:
                return null
        }
    }

    public componentDidMount() {
        this.setScreen("no signature")
        this.loadCurrentSignature()
    }

    public render() {
        return (
            <div className={styles.wrapper}>
                {this.content}
                <div className={styles.buttonsWrapper}>
                    <Button
                        onClick={this.onClear.bind(this)}
                        type="transparent"
                        name="Clear"
                        disabled={!this.clearEnabled}
                    />
                    <Button onClick={this.onSave.bind(this)} name="Save" disabled={!this.saveEnabled} />
                </div>
            </div>
        )
    }

    private async onSave() {
        if (this.sigCanvas.isEmpty()) {
            alert("Can not save empty signature")
            return
        }
        const imgData = this.sigCanvas.toDataURL("image/png")
        this.saveEnabled = false

        await this.props.gate.request("/signatures/add", {data: {json_data: {img: imgData}, type: this.mode}})
        await this.loadCurrentSignature()
        this.setScreen("image")
    }

    private onClear() {
        if (this.sigCanvas) {
            this.sigCanvas.clear()
        }
        this.setScreen("canvas")
    }

    private async loadCurrentSignature() {
        let r = await this.props.gate.request("/signatures/get-my", {data: {type: this.mode}})
        if (r.success) {
            this.imgData = r.data.json_data.img
            this.setScreen("image")
        }

        if (!r.success) {
            this.setScreen("no signature")
        }
    }

    @action
    private setScreen(screen: Screen) {
        this.screen = screen
        switch (screen) {
            case "canvas":
                this.clearEnabled = true
                this.saveEnabled = true
                break
            case "image":
                this.clearEnabled = true
                this.saveEnabled = false
                break
            case "no signature":
                this.clearEnabled = false
                this.saveEnabled = false
                break
        }
    }
}

function NoSignatureBox({onClick}: {onClick: () => void}) {
    return (
        <div className={styles.noSignature} onClick={onClick}>
            <Icon className={styles.noSignatureIcon} width="48" height="41" />
            <p className={styles.noSignatureText}>Click here to create a signature. You can Save it for future use.</p>
        </div>
    )
}
