import React, {Component} from "react"
import {Button} from "../Button"
import styles from "./AddItemButton.module.css"
import {ReactComponent as Icon} from "./plus.svg"

interface Props {
    label?: string
    // TODO Kill `... extends FormDataComponent<FormData>` pattern and give normal type to onClick
    onClick: any
    width?: number
}

export class AddItemButton extends Component<Props, {}> {
    public render() {
        const label = this.props.label || "Create New"
        const width = this.props.width || 128

        return (
            <Button
                label={label}
                onClick={this.props.onClick}
                title="Click to add new item"
                style={{height: "34px", width: `${width}px`, paddingLeft: "12px"}}>
                <Icon className={styles.icon} width="16" height="16" />
            </Button>
        )
    }
}
