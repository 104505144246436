import React, {Component} from "react"
import {Model} from "Model"
import {observer} from "mobx-react"
import {Gate} from "lib/gate/Gate"
import {observable, runInAction} from "mobx"
import {Popup} from "../popup"

interface Props {
    model: Model
    gate: Gate
}

@observer
export class VisitorBar extends Component<Props, {}> {
    @observable
    private isLoading = false

    @observable
    private popup = {visible: false, message: null}
    public render() {
        const visitor = this.props.model.visitor
        const {gate, model} = this.props
        return (
            <>
                {this.popup.visible && (
                    <Popup
                        message={this.popup.message}
                        onOk={() => {
                            this.popup.visible = false
                        }}
                    />
                )}
                <div style={{position: "absolute", right: "30px", top: "20px"}}>
                    You are: {visitor.first_name} {visitor.last_name} ({visitor.role})
                    <button
                        disabled={this.isLoading}
                        onClick={async () => {
                            this.isLoading = true
                            await gate.request("/debug/reload-all")
                            runInAction(() => {
                                this.isLoading = false
                            })
                        }}>
                        Reload Data from V2
                    </button>
                    {"  "}
                    <button
                        disabled={this.isLoading}
                        onClick={async () => {
                            this.isLoading = true
                            const {data} = await gate.request(`/debug/update-passport/${model.user.id}`)
                            runInAction(() => {
                                this.isLoading = false
                                this.popup.visible = true
                                this.popup.message = JSON.stringify(data, undefined, 2)
                            })
                            model.loadPassport(model.user.id)
                        }}>
                        Update Passport
                    </button>
                </div>
            </>
        )
    }
}
