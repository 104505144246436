import React, {Component} from "react"
import styles from "./ListItem.module.css"

export interface ListItemProps {
    selected?: boolean
    index: number
    title: string
    onClick: (index: number) => void
}

export class ListItem extends Component<ListItemProps, {}> {
    public render() {
        return (
            <div
                className={`${styles["item"]} ${this.props.selected ? styles["item--selected"] : ""}`}
                onClick={this.onClick.bind(this)}>
                {this.props.title}
            </div>
        )
    }

    private onClick() {
        this.props.onClick(this.props.index)
    }
}
