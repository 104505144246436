import * as React from "react"
import styles from "./LinkButton.module.css"

interface Props {
    label?: string
    onClick: Function
}

export class LinkButton extends React.Component<Props> {
    onBtnClick(e) {
        this.props.onClick(e)
    }

    render() {
        return (
            <button className={styles["btn"]} onClick={this.onBtnClick.bind(this)}>
                {this.props.label}
            </button>
        )
    }
}
