import React, {Component} from "react"
import {computed, observable} from "mobx"
import {observer} from "mobx-react"
import Select from "react-select"
import {Gate} from "lib/gate/Gate"
import {api_response} from "lib/gate/interfaces"

interface Props {
    gate: Gate
    user_id?: number
    onChange: (user_id: number) => void
}

interface Item {
    value
    label: string
}

@observer
export class StaffSelect extends Component<Props, {}> {
    @observable
    private isLoading = false

    @observable
    private options: Item[] = []

    @computed
    private get selectedValue(): Item {
        for (let option of this.options) {
            if (option.value === this.props.user_id) {
                return option
            }
        }

        return null
    }

    public async componentDidMount() {
        this.isLoading = true
        const {success, data} = await this.props.gate.request("/users/get-all-staff")
        this.isLoading = false
        if (success) {
            this.options = data.map((user: api_response.User) => {
                return {value: user.id, label: `${user.first_name} ${user.last_name}`}
            })
        }
    }

    private onChange(e) {
        this.props.onChange(parseInt(e.value))
    }

    public render() {
        return (
            <Select
                isLoading={this.isLoading}
                options={this.options}
                value={this.selectedValue}
                onChange={this.onChange.bind(this)}
                placeholder="Type Staff member’s name"
            />
        )
    }
}
