import {computed, observable} from "mobx"
import {Action, History, Location} from "history"
import {Gate} from "./lib/gate/Gate"
import {api_response, User, Visitor} from "./lib/gate/interfaces"

export class Model {
    public signatures = {}

    @observable
    public visitor: Visitor

    @observable
    public path: string

    @observable
    public location: Location

    @observable
    public user: User | undefined

    @observable
    public appSettings: any

    @computed
    public get planStaffUserId(): number {
        if (this.appSettings && this.appSettings.plan_staff_user_id) {
            return parseInt(this.appSettings.plan_staff_user_id)
        }

        return 0
    }

    @computed
    public get agreementStaffUserId(): number {
        // return 60347
        if (this.appSettings && this.appSettings.agreement_staff_user_id) {
            return parseInt(this.appSettings.agreement_staff_user_id)
        }

        return 0
    }

    @observable
    public passport: api_response.Milestone[] = []

    public readonly history: History

    constructor(private gate: Gate, history: History) {
        this.history = history
        history.listen(this.onHistoryChange.bind(this))
        this.onHistoryChange(history.location, "PUSH")
    }

    private onHistoryChange(location: Location, action: Action) {
        this.path = location.pathname
        this.location = location
    }

    public async loadAppSettings() {
        let r = await this.gate.request("/app-settings/get")
        this.appSettings = r.data.json_data
    }

    public async loadMe(): Promise<void> {
        let r = await this.gate.request("/users/get-me")
        if (!r.success) {
            alert("API Error!" + JSON.stringify(r.errors))
        }
        this.visitor = r.data
    }

    public async loadUser(user_id: number): Promise<void> {
        let r = await this.gate.request(`/users/${user_id}/get`)
        this.user = r.data
    }

    public async loadPassport(user_id: number): Promise<void> {
        const data = {
            sort: {
                orderBy: "min_hours_attended",
                order: "ASC"
            },
            filter: {
                text: "",
                statuses: null,
                user_ids: [user_id]
            },
            pagination: {
                page: 1,
                pageSize: 10
            }
        }
        let r = await this.gate.request("/milestones/get-all", {data})
        if (r.success) {
            this.passport = r.data
        }
    }
}
