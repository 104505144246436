import React, {Component} from "react"
import {computed, observable} from "mobx"
import {observer} from "mobx-react"
import Select from "react-select"
import {Gate} from "lib/gate/Gate"
import {api_response} from "lib/gate/interfaces"

interface Props {
    gate: Gate
    selected_ids: number[]
    onChange: (selected_ids: number[]) => void
}

interface Item {
    value
    label: string
}

@observer
export class StatusesSelect extends Component<Props, {}> {
    @observable
    private isLoading = false

    @observable
    private options: Item[] = []

    @computed
    private get selectedOptions(): Item[] {
        let arr: Item[] = []
        for (let id of this.props.selected_ids) {
            for (let el of this.options) {
                if (el.value === id) {
                    arr.push(el)
                    break
                }
            }
        }

        return arr
    }

    public async componentDidMount() {
        this.isLoading = true
        const {success, data} = await this.props.gate.request("/student-statuses/get-all")
        this.isLoading = false
        if (success) {
            this.options = data.map((status: api_response.StudentStatus) => {
                return {value: status.id, label: status.name}
            })
        }
    }

    private onChange(items: Item[]) {
        this.props.onChange(
            items.map((item) => {
                return item.value
            })
        )
    }

    public render() {
        return (
            <Select
                isMulti
                isLoading={this.isLoading}
                options={this.options}
                value={this.selectedOptions}
                onChange={this.onChange.bind(this)}
                placeholder="Select statuses"
            />
        )
    }
}
