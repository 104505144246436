import React, {Component} from "react"
import styles from "./button.module.css"

interface Props {
    name?: string
    disabled?: boolean
    onClick: () => void
}

export class Button extends Component<Props, {}> {
    private onClick(event) {
        const {onClick} = this.props
        event.preventDefault()
        event.stopPropagation()
        onClick()
    }

    public render() {
        const {name, disabled} = this.props
        return (
            <button className={styles.button} onClick={this.onClick.bind(this)} disabled={disabled}>
                {name}
            </button>
        )
    }
}
