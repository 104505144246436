import React, {Component} from "react"
import classNames from "classnames"
import {RemoveFilter} from "./RemoveFilter"
import styles from "./MultiSelectFilter.module.css"
import stylesCommon from "./FiltersCommon.module.css"
import {DropdownItem} from "./interfaces"

type selectedItem = string | number

interface Props {
    onChange: (selectedValues: any) => void
    items: DropdownItem[]
    label?: string
    selected?: selectedItem[]
    placeHolder?: string
    noCurrentLabel?: boolean
    onRemove?: () => void
    onReset?: () => void
    right?: boolean
    model?: any
}

interface State {
    selected: DropdownItem[]
    isOpen: boolean
    componentId: string
    search: string
}

export class MultiSelectFilter extends Component<Props, State> {
    private outsideClickListener
    private oldSelected = []

    private delay: number = 600 // delay in ms after search firing
    private isTyping: boolean = false
    private lastType: number = 0

    constructor(props) {
        super(props)
        this.state = {
            selected: [],
            isOpen: false,
            componentId: "",
            search: ""
        }
    }

    componentDidMount(): void {
        /**
         * Selected Items
         */
        let selected = []
        if (this.props.selected) {
            let items = this.props.items || []

            if (this.props.model && this.props.model.valueKey && this.props.model.titleKey) {
                items = this.getModelList()
            }

            items.forEach((i) => {
                if (this.props.selected.indexOf(i.value) > -1) {
                    selected.push(i)
                }
            })
        }

        this.setState(
            {
                selected: selected,
                componentId: "filter-" + Math.round(Math.random() * 10000000)
            },
            () => {
                this.hideOnClickOutside(document.getElementById(this.state.componentId))
                if (this.props.model) {
                    document
                        .getElementById(this.state.componentId + "-list")
                        .addEventListener("scroll", this.onListScroll.bind(this))
                }
            }
        )

        /**
         * If Model present? Load list from model.
         * note: if selected list not empty, we need all data loaded from model
         */
        if (this.props.model && this.props.model.object) {
            if (this.props.selected && this.props.selected.length) {
                this.props.model.object.pagination.pageSize = 100
                this.props.model.object.load()
            } else {
                this.props.model.object.load()
            }
        }
    }

    componentWillUnmount(): void {
        document.removeEventListener("click", this.outsideClickListener)
        if (this.props.model) {
            document.getElementById(this.state.componentId + "-list").removeEventListener("scroll", this.onListScroll)
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (JSON.stringify(this.state.selected) !== JSON.stringify(nextProps.selected)) {
            let selected = [],
                items = this.props.items || []

            if (this.props.model && this.props.model.valueKey && this.props.model.titleKey) {
                items = this.getModelList()
            }

            items.forEach((i) => {
                if (nextProps.selected && nextProps.selected.indexOf(i.value) > -1) {
                    selected.push(i)
                }
            })
            this.setState({selected: selected})
        }
    }

    private onSearch(e) {
        /**
         * Search by model.
         */
        if (this.props.model && this.props.model.object) {
            this.lastType = new Date().getTime()
            if (!this.isTyping) {
                this.isTyping = true
                this.setTimer()
            }
        }

        this.setState({search: e.target.value})
    }

    private setTimer() {
        let currentM = new Date().getTime(),
            rem = currentM - this.lastType

        if (rem >= this.delay) {
            if (this.isTyping) {
                this.startSearch()
                this.isTyping = false
            }
        } else {
            setTimeout(() => {
                this.setTimer()
            }, this.delay)
        }
    }

    private async startSearch() {
        this.props.model.object.updateFilter({text: this.state.search})
        this.props.model.object.pagination.page = 1
        //this.props.model.object.updatePagination({page: 1});

        await this.props.model.object.loadUsingMode("more")
        this.forceUpdate()
    }

    private hideOnClickOutside(element) {
        this.outsideClickListener = (event) => {
            let selected = this.state.selected
            if (selected.length === this.oldSelected.length) {
                if (!element.contains(event.target) && this.state.isOpen) {
                    this.setState({isOpen: false})
                }
            }
            this.oldSelected = selected.slice()
        }
        document.addEventListener("click", this.outsideClickListener)
    }

    private async onListScroll() {
        if (this.props.model && this.props.model.object) {
            let obj = document.getElementById(this.state.componentId + "-list")
            if (obj.scrollHeight - obj.offsetHeight - obj.scrollTop < 1 && !this.props.model.object.isLoading) {
                let r = await this.props.model.object.loadMore()
                console.log("R", r)
                this.forceUpdate()
            }
        }
    }

    private toggleMenu() {
        this.setState({isOpen: !this.state.isOpen})
    }

    private toggleSelected(item, isSelected, e) {
        e.preventDefault()

        let selected = this.state.selected
        if (isSelected) {
            selected.push(item)
        } else {
            selected = selected.filter((i) => i.value !== item.value)
        }

        let value = selected.map((s) => s.value)
        this.setState({selected: selected}, () => {
            this.props.onChange(value.length ? value : null)
        })
    }

    private menuClick(e) {
        e.preventDefault()
        e.stopPropagation()
    }

    private searchItems() {
        let list = [],
            items = this.props.items

        /**
         * Load data from model
         * (if model present)
         */
        if (this.props.model && this.props.model.valueKey && this.props.model.titleKey) {
            items = this.getModelList()
        }

        items.forEach((i) => {
            if (i.title.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1) {
                let isSelected = false

                this.state.selected.forEach((s) => {
                    if (s.value === i.value) {
                        isSelected = true
                    }
                })

                list.push(this.item(i, isSelected))
            }
        })
        return list
    }

    private selectedLabel() {
        let label,
            list = []
        if (this.state.selected.length) {
            this.state.selected.forEach((i) => {
                list.push(i.title)
            })
            label = list.join(", ")
        } else {
            label = "All"
        }

        return label
    }

    private item(data, checked) {
        return (
            <li
                className={styles.filterSubMenuItem}
                key={data.value}
                onClick={this.toggleSelected.bind(this, data, !checked)}>
                <label className={styles.filterSubMenuCheckBox}>
                    <input
                        className={styles.checkBoxInput}
                        type="checkbox"
                        onChange={(e) => e.preventDefault()}
                        checked={checked}
                    />
                    <svg width="20" height="20" className={styles.checkBoxIcon}>
                        <use xlinkHref="#checked" />
                    </svg>
                    <span className={styles.checkBoxText}>{data.title}</span>
                </label>
            </li>
        )
    }

    /**
     * All items, exclude selected
     */
    private allList() {
        let list = [],
            items = this.props.items

        /**
         * Load data from model
         * (if model present)
         */
        if (this.props.model && this.props.model.valueKey && this.props.model.titleKey) {
            items = this.getModelList()
        }

        items.forEach((i) => {
            let isSelected = false
            this.state.selected.forEach((s) => {
                if (i.value === s.value) {
                    isSelected = true
                }
            })
            if (!isSelected) {
                list.push(this.item(i, false))
            }
        })

        return list
    }

    private getModelList() {
        let items = []
        this.props.model.object.items.forEach((i) => {
            items.push({
                value: i[this.props.model.valueKey],
                title: i[this.props.model.titleKey]
            })
        })
        items = this.listUniq(items)
        return items
    }

    private listUniq(data) {
        let list = data.slice(),
            items = []

        list.forEach((item) => {
            let exists = false

            items.forEach((i) => {
                if (i.value === item.value) exists = true
            })

            if (!exists) items.push(item)
        })
        return items
    }

    filter() {
        return (
            <React.Fragment>
                <button
                    type="button"
                    className={classNames(
                        stylesCommon.filterBtn,
                        stylesCommon.filterBtnDropArrow,
                        this.state.isOpen && stylesCommon.filterBtnActive
                    )}
                    onClick={this.toggleMenu.bind(this)}>
                    <div className={stylesCommon.filterBtnWrap}>
                        {!this.props.noCurrentLabel ? (
                            <span>
                                {this.props.label}: {this.selectedLabel()}
                            </span>
                        ) : (
                            <span>{this.props.label}</span>
                        )}
                    </div>
                </button>
                {this.props.onRemove && <RemoveFilter onClick={this.props.onRemove.bind(this)} />}

                <div
                    className={classNames(styles.filterSubMenu, this.props.right && styles.filterSubMenuRightPosition)}
                    onClick={this.menuClick.bind(this)}
                    style={{display: this.state.isOpen ? "block" : "none"}}>
                    {this.props.onReset && (
                        <button className={styles.restoreDefault} onClick={this.props.onReset.bind(this)}>
                            Restore Defaults
                        </button>
                    )}
                    {this.props.placeHolder && (
                        <div className={styles.searchWrapper}>
                            <input
                                className={classNames(stylesCommon.filterSearch, stylesCommon.filterSearchSubMenu)}
                                type="text"
                                value={this.state.search}
                                onChange={this.onSearch.bind(this)}
                                placeholder={this.props.placeHolder}
                                autoComplete="new-password"
                            />
                        </div>
                    )}

                    <div className={styles.filterSubMenuWrapper} id={this.state.componentId + "-list"}>
                        {this.state.search.trim() === "" ? (
                            <React.Fragment>
                                {this.state.selected.length > 0 && (
                                    <React.Fragment>
                                        <h5 className={styles.filterSubMenuListTitle}>Selected</h5>
                                        <ul className={styles.filterSubMenuList}>
                                            {this.state.selected.map((i) => this.item(i, true))}
                                        </ul>
                                    </React.Fragment>
                                )}

                                <React.Fragment>
                                    {this.allList().length > 0 && (
                                        <h5 className={styles.filterSubMenuListTitle}>All param</h5>
                                    )}
                                    <ul className={styles.filterSubMenuList}>{this.allList()}</ul>
                                </React.Fragment>
                            </React.Fragment>
                        ) : (
                            this.searchItems()
                        )}
                    </div>
                </div>
            </React.Fragment>
        )
    }

    public render() {
        return <div id={this.state.componentId}>{this.filter()}</div>
    }
}
