import React, {Component} from "react"
import {api_response} from "lib/gate/interfaces"
import {Link} from "react-router-dom"
import styles from "./line.module.css"
import {OpenButton} from "./parts/open-button"
import {observer} from "mobx-react"
import {computed, observable} from "mobx"
import {SubTable} from "../sub-table"
import classNames from "classnames"
import {Icons} from "../sub-table/icons"
import {Column} from "../../table"
import moment from "moment"

interface Props {
    item: api_response.StudentListItem
    token: string
    tableStructure: Column[]
}

@observer
export class Line extends Component<Props, {}> {
    @observable
    private isOpened: boolean = false

    @computed
    private get isButtonDisabled(): boolean {
        const {milestones} = this.props.item
        if (milestones.length === 0 || milestones[0] === null) {
            return true
        }

        return false
    }

    private onClick() {
        this.isOpened = !this.isOpened
    }

    public render() {
        const {tableStructure, item, token} = this.props
        return (
            <React.Fragment>
                <tr>
                    {tableStructure.map((column: Column) => {
                        return this.getCell(column)
                    })}
                </tr>

                {this.isOpened && (
                    <OpenedSection
                        item={item}
                        token={token}
                        totalColumns={tableStructure.length}
                        milestones={item.milestones}
                    />
                )}
            </React.Fragment>
        )
    }

    private getCell(c: Column): JSX.Element {
        const {item, token} = this.props

        switch (c.id) {
            case "full_name":
                return (
                    <NameCell
                        key={c.title}
                        column={c}
                        item={item}
                        token={token}
                        onClick={this.onClick.bind(this)}
                        isOpened={this.isOpened}
                        isDisabled={this.isButtonDisabled}
                    />
                )
            case "milestones_status":
                return <StatusCell key={c.title} item={item} />

            case "graduation_date":
                return <DateCell key={c.title} column={c} item={item} />

            default:
                return <DefaultCell key={c.title} column={c} item={item} />
        }
    }
}

interface NameCellProps {
    column: Column
    item: api_response.StudentListItem
    token: string
    onClick: () => void
    isOpened: boolean
    isDisabled: boolean
}

function NameCell({item, token, onClick, isOpened, isDisabled}: NameCellProps) {
    return (
        <td className={classNames(styles.cell, styles.cellName)}>
            <div className={styles.nameWrapper}>
                <OpenButton onClick={onClick} isOpen={isOpened} disabled={isDisabled} />
                <Link className={styles.name} to={`/students/${item.id}?token=${token}`}>
                    {item.first_name} {item.last_name}
                </Link>
            </div>
        </td>
    )
}

function StatusCell({item}: {item: api_response.StudentListItem}) {
    return (
        <td className={classNames(styles.cell, styles.cellMilestone)}>
            <div className={styles.milestoneWrapper}>
                <Icons name={item["passport_status"]} />
                <span> {item["passport_status"]}</span>
            </div>
        </td>
    )
}

interface DefaultCellProps {
    column: Column
    item: api_response.StudentListItem
}

function DefaultCell({column, item}: DefaultCellProps) {
    return <td className={styles.cell}>{item[column.id]}</td>
}

function DateCell({column, item}: DefaultCellProps) {
    let date = moment.utc(item[column.id]).format("MMMM DD, YYYY")
    if (date === "Invalid date") {
        date = ""
    }
    return <td className={styles.cell}>{date}</td>
}

interface OpenedSectionProps {
    totalColumns: number
    milestones: api_response.Milestone[]
    item: api_response.StudentListItem
    token: string
}

function OpenedSection({totalColumns, milestones, item, token}: OpenedSectionProps) {
    const link = `/students/${item.id}?token=${token}`
    return (
        <tr>
            <td colSpan={2} className={styles.openedCell}>
                <SubTable item={milestones} link={link} />
            </td>
            <td colSpan={totalColumns - 2} className={styles.openedCell} />
        </tr>
    )
}
