import React, {Component} from "react"
import styles from "./RemoveFilter.module.css"
import classNames from "classnames"

interface Props {
    onClick: () => void
}

export class RemoveFilter extends Component<Props, {}> {
    public render() {
        return (
            <button
                className={classNames(styles.ellipseCloseButton, styles.closeButton)}
                type="button"
                onClick={this.props.onClick.bind(this)}>
                <svg width="16" height="16" className={styles.ellipseCloseButtonIcon}>
                    <use xlinkHref="#ellipse-close" />
                </svg>
                <span className={styles.ellipseCloseButtonText}>Close</span>
            </button>
        )
    }
}
