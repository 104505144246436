import React, {Component} from "react"
import {Model} from "Model"
import {observer} from "mobx-react"
import styles from "./WorkspacePage.module.css"
import {NavigationTab} from "components/navigation-tab"
import {Gate} from "lib/gate/Gate"
import {IList} from "lib/gate/IList"
import {api_response, MilestonesFilter, Sort} from "lib/gate/interfaces"
import {observable} from "mobx"
import {List} from "lib/gate/List"

import {Search} from "components/search"
import {Page} from "../../components/misc/page"
import {H1} from "../../components/misc/h1"
import {Pagination} from "../studends/parts/paginator"
import {Filter} from "./parts/Filter"
import {Table} from "./parts/table"

interface Props {
    campus_ids: number[]
    program_ids: number[]
    model: Model
    gate: Gate
}

@observer
export class WorkspacePage extends Component<Props, {}> {
    @observable
    private list: IList<MilestonesFilter, api_response.Milestone>

    private getRandomInt(min, max): number {
        // From docs https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random
        min = Math.ceil(min)
        max = Math.floor(max)
        return Math.floor(Math.random() * (max - min)) + min
    }

    constructor(props) {
        super(props)
        this.list = new List(this.props.gate, "/milestones")
    }

    public componentDidMount(): void {
        // this.list.setSort({order: "DESC", orderBy: "mariadb_updated_at"})
        // this.list.setSort({order: "DESC", orderBy: "graduation_date"})
        this.list.setSort({order: "DESC", orderBy: "created_at"})
        this.list.load()
    }

    private onFilter(filter: MilestonesFilter) {
        const list = this.list
        const oldFilter = list.filter
        const oldPage = list.pagination.page
        list.setFilter(filter)
        list.setPage(1)
        const filterChanged: boolean = JSON.stringify(oldFilter) !== JSON.stringify(filter)
        const pageChanged: boolean = oldPage !== 1
        if (filterChanged || pageChanged) {
            list.load()
        }
    }

    public render() {
        const {gate} = this.props
        const list = this.list

        return (
            <Page htmlTitle="Students List">
                <header className={styles.header}>
                    <H1>Student Passport</H1>
                    <Search
                        onChange={(text: string) => {
                            const filter: MilestonesFilter = {...list.filter, text: text}
                            this.onFilter(filter)
                        }}
                        placeholder={`Enter student name`}
                    />
                </header>
                <div className={styles.navigationWrapper}>
                    <NavigationTab token={gate.getToken()} activeTabKey="workspace" />
                </div>

                <div className={styles.filterWrapper}>
                    <Filter
                        campus_ids={this.props.campus_ids}
                        program_ids={this.props.program_ids}
                        onChange={this.onFilter.bind(this)}
                        gate={this.props.gate}
                    />
                </div>

                <Table
                    gate={gate}
                    items={list.items}
                    isLoading={list.isLoading}
                    sort={this.list.sort}
                    onChange={(sort: Sort) => {
                        if (!sort.order) {
                            sort.order = "ASC"
                        }
                        this.list.setSort(sort)
                        this.list.load()
                    }}
                />

                <h2>Total:{list.pagination.totalItems}</h2>
                <div className={styles.paginationWrapper}>
                    <Pagination
                        totalPages={list.pagination.totalPages}
                        onChange={(page) => {
                            list.setPage(page)
                            list.load()
                        }}
                        page={list.pagination.page}
                        isLoading={list.isLoading}
                    />
                </div>
            </Page>
        )
    }
}
