import React, {Component, RefObject} from "react"
import {DropdownButton} from "./DropdownButton"
import {List} from "../../lists/List/List"
import styles from "./Dropdown.module.css"
import {ItemVO} from "../../../index"

interface Props {
    selectedIndexes: number[]
    items: ItemVO[]
    onClick: (item: ItemVO, index: number) => void
}

interface State {
    isOpened: boolean
}

export class Dropdown extends Component<Props, State> {
    private componentRef: RefObject<HTMLDivElement>

    constructor(props) {
        super(props)
        this.state = {isOpened: false}
        this.componentRef = React.createRef()
    }

    private onMouseDown(e: MouseEvent) {
        if (!this.componentRef) {
            return
        }

        const div: HTMLDivElement = this.componentRef.current
        if (!div) {
            return
        }

        if (this.componentRef.current.contains(e.target as Node)) {
            return
        }

        this.setState({isOpened: false})
    }

    public async componentDidMount() {
        document.addEventListener("mousedown", this.onMouseDown.bind(this))
    }

    public componentWillUnmount() {
        document.removeEventListener("mousedown", this.onMouseDown.bind(this))
    }

    private getText(): string {
        let selectedIndexes: number[] = this.props.selectedIndexes

        if (selectedIndexes.length === 0) {
            return "-- select --"
        }

        let arr: any[] = this.props.items.filter((item: ItemVO, index: number) => {
            if (selectedIndexes.indexOf(index) !== -1) {
                return true
            }

            return false
        })

        arr = arr.map((item: ItemVO) => {
            return item.title
        })

        return arr.join(", ")
    }

    public render() {
        return (
            <div className={styles["wrapper"]} ref={this.componentRef}>
                <DropdownButton
                    text={this.getText()}
                    isOpen={this.state.isOpened}
                    onClick={this.onButtonClick.bind(this)}
                />
                {this.state.isOpened && (
                    <div className={styles["listWrapper"]}>
                        <List
                            selectedIndexes={this.props.selectedIndexes}
                            items={this.props.items}
                            onClick={this.onClick.bind(this)}
                        />
                    </div>
                )}
            </div>
        )
    }

    private onClick(item: ItemVO, index: number) {
        this.props.onClick(this.props.items[index], index)
    }

    private onButtonClick() {
        this.setState({isOpened: !this.state.isOpened})
    }
}
