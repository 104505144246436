import React, {Component} from "react"
import styles from "./Body.module.css"
import "./variables.css"
import "./scaffolding.css"
import "./global.css"

interface Props {}

export class Body extends Component<Props, {}> {
    public render() {
        return <div className={styles.mainBody}>{this.props.children}</div>
    }
}
