import React, {Component} from "react"
import ReactDOM from "react-dom"
import styles from "./popup.module.css"
import classNames from "classnames"

interface Props {
    message: string
    onOk: () => void
    onCancel?: () => void
}

export class Popup extends Component<Props, {}> {
    public componentDidMount() {
        document.getElementById("appModal").style.display = "block"
    }

    public componentWillUnmount() {
        document.getElementById("appModal").style.display = "none"
    }

    public render() {
        const {onCancel, onOk, message} = this.props

        let html = (
            <article className={styles.popup}>
                <div className={styles.iconWrapper}>

                </div>
                <div className={styles.body}>
                    <h1 className={styles.title}></h1>
                    <pre>{message}</pre>
                    {/*<p className={styles.text}>This action cannot be undone</p>*/}
                    <div className={styles.buttonWrapper}>
                        {onCancel && (
                            <button className={styles.button} onClick={onCancel}>
                                Cancel
                            </button>
                        )}
                        <button className={classNames(styles.button, styles.secondary)} onClick={onOk}>
                            OK
                        </button>
                    </div>
                </div>
            </article>
        )

        return ReactDOM.createPortal(html, document.getElementById("appModal"))
    }
}
