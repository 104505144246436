import React, {Component} from "react"
import styles from "./DropdownButton.module.css"
import {ReactComponent as Icon} from "./arrow-up.svg"

interface Props {
    text: string
    isOpen: boolean
    onClick: () => void
}

export class DropdownButton extends Component<Props, {}> {
    public render() {
        let css = styles["button"]
        if (this.props.isOpen) {
            css = css + " " + styles["isOpen"]
        } else {
            css = css + " " + styles["isClose"]
        }

        return (
            <button className={css} onClick={this.props.onClick}>
                {this.props.text}
                <Icon width={10} height={17} className={styles["icon"]} />
            </button>
        )
    }
}
