import {action, observable} from "mobx"

export class SelectionModel {
    /**
     * ALL Selected IDs
     * NOTE! Table display only some selected elements at a time
     * Length of all array may be 1000 and more. But table display usually pageSize=20
     */
    @observable
    public all: number[] = []

    @action
    public add(addItems: number[]) {
        for (let i of addItems) {
            if (this.all.indexOf(i) === -1) {
                this.all.push(i)
            }
        }
    }

    @action
    public remove(removeItems: number[]) {
        let arr = []
        for (let i of this.all) {
            if (removeItems.indexOf(i) === -1) {
                arr.push(i)
            }
        }
        this.all = arr
    }

    public contains(id: number): boolean {
        return this.all.indexOf(id) !== -1
    }
}
