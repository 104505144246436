import React, {Component} from "react"
import styles from "./button.module.css"
import classNames from "classnames"

interface Props {
    name?: string
    disabled?: boolean
    type?: "cancel" | "ok"
    onClick: () => void
}

export class Button extends Component<Props, {}> {
    private onClick(event) {
        const {onClick} = this.props
        event.preventDefault()
        event.stopPropagation()
        onClick()
    }

    public render() {
        const {name, disabled, type} = this.props
        return (
            <button
                className={classNames(
                    styles.button,
                    type === "cancel" && styles.buttonCancel,
                    type === "ok" && styles.buttonOk
                )}
                onClick={this.onClick.bind(this)}
                disabled={disabled}>
                {name}
            </button>
        )
    }
}
