import React, {Component} from "react"
import styles from "./PaginatorSize.module.css"

interface Props {
    defaultPageSize: number
    onChange: (value) => void
}

export class PaginatorSize extends Component<Props, {}> {
    private sizes = [30, 60]

    constructor(props) {
        super(props)

        if (this.sizes.indexOf(this.props.defaultPageSize) === -1) {
            // Default Page Size must be available for selection
            // If it is not in list we should add it
            this.sizes.push(this.props.defaultPageSize)
            this.sizes.sort()
        }

        this.state = {
            isOpened: false,
            selectedSize: this.props.defaultPageSize
        }
    }

    public render() {
        return (
            <React.Fragment>
                <span className={styles.text}> Show</span>
                <div className={styles.selectorWrapper}></div>
            </React.Fragment>
        )
    }
}
