import React, {Component} from "react"
import classNames from "classnames"
import styles from "./HeaderCell.module.css"

export interface HeaderCellProps {
    title: string
    columnId?: string
    orderBy?: string
    order?: "ASC" | "DESC"
    key?
    onClick?: (columnId: string) => void
    width?: number
}
export class HeaderCell extends Component<HeaderCellProps> {
    public render() {
        const isSelected = this.props.columnId === this.props.orderBy

        let style: any = {}
        if (this.props.width) {
            style.width = this.props.width + "px"
        }

        let directionButton = ""
        if (isSelected && this.props.order === "ASC") {
            directionButton = styles["button--down"]
        }
        if (isSelected && this.props.order === "DESC") {
            directionButton = styles["button--up"]
        }

        return (
            <th style={style} className={classNames(styles["cell"], isSelected && styles["cell--active"])}>
                <button
                    className={classNames(styles["button"], directionButton)}
                    type="button"
                    onClick={this.onClick.bind(this)}>
                    {this.props.title}
                    {isSelected && (
                        <svg width="11" height="7" className={styles["icon"]}>
                            <use xlinkHref="#arrow-thriangle" />
                        </svg>
                    )}
                </button>
            </th>
        )
    }

    private onClick() {
        this.props.onClick(this.props.columnId)
    }
}
