import classNames from "classnames"
import React, {Component} from "react"
import styles from "./PaginatorNumbers.module.css"

interface Props {
    totalPages: number
    page: number
    isLoading?: boolean
    onChange: (page) => void
}

export class PaginatorNumbers extends Component<Props, {}> {
    private range = 4 // Paginator step range

    private changePage(page, e) {
        e.preventDefault()
        // document.getElementById("main-content").scrollTo(0, 0)
        if (this.props.onChange) {
            this.props.onChange(page)
        }
    }

    private getItem(page) {
        return (
            <div
                onClick={this.changePage.bind(this, page)}
                className={classNames(styles["link"], page === this.props.page && styles["linkActive"])}
                key={page}>
                <span className={styles["text"]}>{page}</span>
            </div>
        )
    }

    render() {
        /**
         * We need to show paginator, if pages less then 1,
         * or if we click one
         */

        if (this.props.totalPages <= 1 || this.props.isLoading) {
            return <nav className={styles["wrapper"]}></nav>
        }

        let list: any = [],
            from = this.props.page - this.range <= 0 ? 1 : this.props.page - this.range,
            to = from + this.range * 2

        if (to > this.props.totalPages) to = this.props.totalPages

        if (this.props.page === to) {
            from = to - this.range * 2
            if (from < 1) from = 1
        }

        for (let i = from; i <= to; i++) {
            list.push(this.getItem(i))
        }

        return (
            <nav className={styles["wrapper"]}>
                {this.props.page > 1 && (
                    <div
                        onClick={this.changePage.bind(this, from)}
                        className={classNames(styles["link"], styles["ButtonLeft"])}></div>
                )}

                {list}

                {this.props.page !== this.props.totalPages && (
                    <div
                        onClick={this.changePage.bind(this, to)}
                        className={classNames(styles["link"], styles["ButtonRight"])}></div>
                )}
            </nav>
        )
    }
}
