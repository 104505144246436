import React, {Component} from "react"
import ReactDOM from "react-dom"
import styles from "./SignaturePopup.module.css"
import {ReactComponent as PenIcon} from "./parts/images/pen.svg"
import {observer} from "mobx-react"

import {Signature} from "./parts/signature"
import {Button} from "./parts/button"
import {Model} from "Model"
import {Gate} from "lib/gate/Gate"
import {api_response, User} from "lib/gate/interfaces"
import {observable} from "mobx"

interface Props {
    model: Model
    gate: Gate
    onClose: () => void
    milestone: api_response.Milestone
}

@observer
export class SignaturePopup extends Component<Props, {}> {
    @observable
    private user: User

    public async componentDidMount() {
        document.getElementById("appModal").style.display = "flex"
        const {data} = await this.props.gate.request(`/users/${this.props.milestone.user_id}/get`)
        this.user = data
    }

    public componentWillUnmount() {
        document.getElementById("appModal").style.display = "none"
    }

    public render() {
        const {visitor} = this.props.model
        const {milestone} = this.props
        console.log(visitor)

        let userName = "&nbsp;"
        if (this.user) {
            userName = `${this.user.first_name} ${this.user.last_name}`
        }

        let html = (
            <div className={styles.wrapper}>
                <div className={styles.content}>
                    <header className={styles.imageWrapper}>
                        <div className={styles.marker}>
                            <PenIcon className={styles.markerIcon} width="13" height="13" />
                        </div>
                        <img src={require("./parts/images/goodgradesar.jpg")} alt="" className={styles.image} />
                    </header>
                    <main className={styles.main}>
                        <div className={styles.numberWrapper}>
                            <span className={styles.number}>{milestone.prototype_id}</span>
                        </div>
                        <h2 className={styles.title}>{userName}</h2>
                        <p className={styles.text}>Has attained the following Milestone</p>
                        <p className={styles.userRole}>{milestone.name}</p>
                        <div className={styles.signatureWrapper}></div>
                        <Signature model={this.props.model} gate={this.props.gate} />
                    </main>
                    <footer className={styles.footer}>
                        <Button onClick={this.props.onClose} type="cancel" name="Cancel" />
                        <Button onClick={this.onSign.bind(this)} type="ok" name="APPLY SIGNATURE" />
                    </footer>
                </div>
            </div>
        )

        return ReactDOM.createPortal(html, document.getElementById("appModal"))
    }

    private async onSign() {
        const milestone = this.props.milestone
        await this.props.gate.request(`/milestones/${milestone.id}/sign`)
        this.props.model.loadPassport(milestone.user_id)
        this.props.onClose()
    }
}
