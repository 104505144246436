import React, {Component} from "react"
import {Model} from "Model"
import {observer} from "mobx-react"
import styles from "./StudentsPage.module.css"
import {NavigationTab} from "components/navigation-tab"
import {Gate} from "lib/gate/Gate"
import {IList} from "lib/gate/IList"
import {api_response, Sort, StudentsFilter} from "lib/gate/interfaces"
import {computed, observable} from "mobx"
import {List} from "lib/gate/List"
import {Table} from "./parts/table"
import {Filter} from "./parts/filter"
import {Column} from "./parts/table/table"
import {Page} from "components/misc/page"
import {H1} from "components/misc/h1"
import {Pagination} from "./parts/paginator"
import {Search} from "components/search"

interface Props {
    model: Model
    gate: Gate
}

const TABLE_STRUCTURE: Column[] = [
    {title: "Name", id: "full_name", server_id: "first_name"},
    {title: "Milestone status", id: "milestones_status", server_id: "NA"},
    {title: "Campus", id: "campus_name", server_id: "location"},
    {title: "Program", id: "program_name", server_id: "major"},
    {title: "Grad. Date", id: "graduation_date", server_id: "graduation_date"}
]

@observer
export class StudentsPage extends Component<Props, {}> {
    @observable
    private list: IList<StudentsFilter, api_response.StudentListItem>

    /**
     * Temporary method to test icons
     */
    @computed
    private get testItems(): api_response.StudentListItem[] {
        // @ts-ignore
        return this.list.items.map((item) => {
            const milestones = item.milestones.map((m: api_response.Milestone) => {
                const availableStatuses: api_response.MilestoneStatus[] = [
                    "completed",
                    "in progress",
                    "overdue",
                    "unreached"
                ]
                const randomIndex = this.getRandomInt(0, availableStatuses.length)
                return {...m, status: availableStatuses[randomIndex]}
            })

            return {...item, milestones: milestones}
        })
    }

    private getRandomInt(min, max): number {
        // From docs https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random
        min = Math.ceil(min)
        max = Math.floor(max)
        return Math.floor(Math.random() * (max - min)) + min
    }

    constructor(props) {
        super(props)
        this.list = new List(this.props.gate, "/users")
    }

    public componentDidMount(): void {
        // this.list.setSort({order: "DESC", orderBy: "mariadb_updated_at"})
        // this.list.setSort({order: "DESC", orderBy: "graduation_date"})
        this.list.setSort({order: "DESC", orderBy: "location"})
        this.list.load()
    }

    private onFilter(filter: StudentsFilter) {
        const list = this.list
        const oldFilter = list.filter
        const oldPage = list.pagination.page
        list.setFilter(filter)
        list.setPage(1)
        const filterChanged: boolean = JSON.stringify(oldFilter) !== JSON.stringify(filter)
        const pageChanged: boolean = oldPage !== 1
        if (filterChanged || pageChanged) {
            list.load()
        }
    }

    public render() {
        const {gate} = this.props
        const list = this.list

        return (
            <Page htmlTitle="Students List">
                <header className={styles.header}>
                    <H1>Student Passport</H1>
                    <Search
                        onChange={(text: string) => {
                            console.log("On Submit")
                            const filter: StudentsFilter = {...list.filter, text: text}
                            this.onFilter(filter)
                        }}
                        placeholder={`Enter student name`}
                    />
                </header>
                <div className={styles.navigationWrapper}>
                    <NavigationTab token={gate.getToken()} activeTabKey="students" />
                </div>
                <Filter
                    gate={gate}
                    onChange={(filter: StudentsFilter) => {
                        filter.text = list.filter.text
                        this.onFilter(filter)
                    }}
                />
                <Table
                    items={list.items}
                    isLoading={list.isLoading}
                    token={gate.getToken()}
                    tableStructure={TABLE_STRUCTURE}
                    sort={this.list.sort}
                    onChange={(sort: Sort) => {
                        if (!sort.order) {
                            sort.order = "ASC"
                        }
                        this.list.setSort(sort)
                        this.list.load()
                    }}
                />
                <h2>Total:{list.pagination.totalItems}</h2>
                <div className={styles.paginationWrapper}>
                    <Pagination
                        totalPages={list.pagination.totalPages}
                        onChange={(page) => {
                            list.setPage(page)
                            list.load()
                        }}
                        page={list.pagination.page}
                        isLoading={list.isLoading}
                    />
                </div>
            </Page>
        )
    }
}
