import React, {Component} from "react"
import styles from "./Cell.module.css"

interface Props {
    item?
    itemKey?: string
}
export class Cell extends Component<Props> {
    public render() {
        const value = this.props.item[this.props.itemKey]
        return (
            <td className={styles["cell"]}>
                <div className={styles["wrapper"]}>{value}</div>
            </td>
        )
    }
}
