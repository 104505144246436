import React, {Component} from "react"
import {Model} from "Model"
import {Gate} from "lib/gate/Gate"
import styles from "./prototype-popup.module.css"
import {ReactComponent as Icon} from "./paper.svg"
import {Input} from "./parts/input"
import {TextArea} from "./parts/text-area"
import {Checkbox} from "./parts/checkbox"
import {UploadImage} from "./parts/upload-image"
import ReactDOM from "react-dom"
import {api_response} from "lib/gate/interfaces"
import {observer} from "mobx-react"
import {observable, toJS} from "mobx"
import {FormField} from "./parts/FormField"
import {FormLabel} from "./parts/FormLabel"
import {CampusesSelect} from "components/selects/CampusesSelect"
import {ProgramsSelect} from "components/selects/ProgramsSelect"
import {Button} from "components/buttons/button"
import {DaysBefore} from "./parts/DaysBefore"
import {DaysAfter} from "./parts/DaysAfter"
import {StaffSelect} from "./parts/StaffSelect"
import {StatusesSelect} from "./parts/StatusesSelect"

interface Props {
    model: Model
    gate: Gate
    onClose: () => void
    itemId?: number
}

@observer
export class PrototypePopup extends Component<Props, {}> {
    @observable isLoading: boolean = false

    @observable
    private prototype: Partial<api_response.Prototype> = {
        name: "",
        description: "",
        min_hours_attended: 0,
        max_hours_attended: 0,
        duration: 0,
        json_data: {
            campus_ids: [],
            status_ids: [],
            program_ids: [],
            notify_staff: false,
            notify_students: false,
            reminders_before: [],
            reminders_after: [],
            student_statuses: []
        }
    }

    public async componentDidMount() {
        document.getElementById("appModal").style.display = "flex"
        const {itemId, gate} = this.props
        if (itemId) {
            const r = await gate.request(`/prototypes/${itemId}/get`)
            if (r.success) {
                const data = r.data
                data.json_data.reminders_before = data.json_data.reminders_before || []
                data.json_data.reminders_after = data.json_data.reminders_after || []
                data.json_data.student_statuses = data.json_data.student_statuses || []
                this.prototype = data
            }
        }
    }

    public componentWillUnmount() {
        document.getElementById("appModal").style.display = "none"
    }

    private integerToString(value: number): string {
        if (value === 0) {
            return "0"
        }
        return value.toString()
    }

    public render() {
        const prototype = this.prototype
        const integerToString = this.integerToString
        const html = (
            <>
                {this.isLoading && <h1 style={{color: "blue"}}>Saving ...</h1>}
                <div className={styles.popup}>
                    <Icon className={styles.icon} width="38" height="41" />
                    <header>
                        <h1 className={styles.title}>Configure Milestone</h1>
                    </header>
                    <div className={styles.form}>
                        <div className={styles.scrolledWrapper}>
                            <div className={styles.leftColumn}>
                                <FormField label="Name" required>
                                    <Input
                                        onChange={(value) => {
                                            prototype.name = value
                                        }}
                                        value={this.prototype.name}
                                        placeholder="Name of the Milestone"
                                    />
                                </FormField>

                                <FormField label="Description" required>
                                    <TextArea
                                        value={prototype.description}
                                        onChange={(value) => {
                                            prototype.description = value
                                        }}
                                    />
                                </FormField>

                                <FormLabel label="Notify Students">
                                    <Checkbox
                                        onChange={(value) => {
                                            prototype.json_data.notify_students = value
                                        }}
                                        value={prototype.json_data.notify_students}
                                    />
                                </FormLabel>

                                {/*<FormField label="Description" required>
                                    <TextArea
                                        value={prototype.description}
                                        placeholder="Content for the notification"
                                        onChange={(value) => {}}
                                    />
                                </FormField>*/}

                                <FormLabel label="Notify staff">
                                    <Checkbox
                                        onChange={(value) => {
                                            prototype.json_data.notify_staff = value
                                        }}
                                        value={prototype.json_data.notify_staff}
                                    />
                                </FormLabel>

                                <div className={styles.selectWrapper}>
                                    <StaffSelect
                                        gate={this.props.gate}
                                        user_id={prototype.json_data.staff_user_id}
                                        onChange={(user_id: number) => {
                                            prototype.json_data.staff_user_id = user_id
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={styles.rightColumn}>
                                <div className={styles.lineWrapper}>
                                    <FormField label="Trigger" required>
                                        <Input
                                            onChange={(value) => {
                                                prototype.min_hours_attended = parseInt(value) || 0
                                            }}
                                            value={integerToString(prototype.min_hours_attended)}
                                            placeholder="Attendance hours"
                                        />
                                    </FormField>

                                    <FormField label="Student statuses">
                                        <StatusesSelect
                                            gate={this.props.gate}
                                            selected_ids={prototype.json_data.student_statuses}
                                            onChange={(ids: number[]) => {
                                                prototype.json_data.student_statuses = ids
                                            }}
                                        />
                                    </FormField>
                                </div>
                                <div className={styles.lineWrapper}>
                                    <FormField label="Duration (days)">
                                        <Input
                                            onChange={(value) => {
                                                prototype.duration = parseInt(value) || 0
                                            }}
                                            value={integerToString(prototype.duration)}
                                            placeholder="Days to confirm the Milestone"
                                        />
                                    </FormField>

                                    <FormField label="Campuses">
                                        <CampusesSelect
                                            gate={this.props.gate}
                                            selected_ids={prototype.json_data.campus_ids}
                                            onChange={(ids) => {
                                                prototype.json_data.campus_ids = ids
                                            }}
                                        />
                                    </FormField>
                                </div>
                                <div className={styles.lineWrapper}>
                                    <FormField label="Threshold">
                                        <Input
                                            onChange={(value) => {
                                                prototype.max_hours_attended = parseInt(value) || 0
                                            }}
                                            value={integerToString(prototype.max_hours_attended)}
                                            placeholder="Hours already attended"
                                        />
                                    </FormField>

                                    <FormField label="Programs">
                                        <ProgramsSelect
                                            gate={this.props.gate}
                                            campus_ids={prototype.json_data.campus_ids}
                                            selected_ids={prototype.json_data.program_ids}
                                            onChange={(ids) => {
                                                prototype.json_data.program_ids = ids
                                            }}
                                        />
                                    </FormField>
                                </div>
                                <div className={styles.lineWrapper}>
                                    <FormField label="Reminder before Duration ends">
                                        <DaysBefore
                                            days={prototype.json_data.reminders_before}
                                            onChange={(days: number[]) => {
                                                prototype.json_data.reminders_before = days
                                            }}
                                        />
                                    </FormField>

                                    <FormField label="Reminder after Duration ends">
                                        <DaysAfter
                                            days={prototype.json_data.reminders_after}
                                            onChange={(days: number[]) => {
                                                prototype.json_data.reminders_after = days
                                            }}
                                        />
                                    </FormField>
                                </div>
                                <div className={styles.uploadWrpper}>
                                    <FormField label="Thumbnail image" required>
                                        <UploadImage />
                                    </FormField>
                                </div>
                            </div>
                        </div>
                        <div className={styles.btnWrapper}>
                            <Button onClick={this.props.onClose} type="cancel" name="Cancel" />
                            <Button onClick={this.onSave.bind(this)} type="ok" name="Ok" />
                        </div>
                    </div>
                </div>
            </>
        )

        return ReactDOM.createPortal(html, document.getElementById("appModal"))
    }

    private async onSave() {
        if (this.props.itemId) {
            this.doEdit()
            return
        }

        this.doSave()
    }

    private async doSave() {
        this.isLoading = true
        let r = await this.props.gate.request("/prototypes/add", {data: this.prototype})
        this.isLoading = false
        if (r.success) {
            this.props.onClose()
        }
    }

    private async doEdit() {
        this.isLoading = true
        let data = toJS(this.prototype)
        delete data.id
        delete data.created_at
        delete data.updated_at
        delete data.deleted_at
        let r = await this.props.gate.request(`/prototypes/${this.props.itemId}/edit`, {data})
        this.isLoading = false
        if (r.success) {
            this.props.onClose()
        }
    }
}
