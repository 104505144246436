import React, {Component} from "react"
import {computed, observable} from "mobx"
import {observer} from "mobx-react"
import Select from "react-select"
import {api_response} from "../../../lib/gate/interfaces"
import {Gate} from "../../../lib/gate/Gate"

interface Props {
    gate: Gate
    selected_ids: number[]
    onChange: (selected_id: number[]) => void
}

interface Item {
    value
    label: string
}

@observer
export class CampusesSelect extends Component<Props, {}> {
    @observable
    private isLoading = false

    @observable
    private campuses: Item[] = []

    @computed
    private get selectedCampuses(): Item[] {
        let arr: Item[] = []
        for (let id of this.props.selected_ids) {
            for (let el of this.campuses) {
                if (el.value === id) {
                    arr.push(el)
                    break
                }
            }
        }

        return arr
    }

    public async componentDidMount() {
        const data = {
            sort: {
                orderBy: "created_at",
                order: "ASC"
            },
            filter: {},
            pagination: {
                page: 1,
                pageSize: 100
            }
        }

        this.isLoading = true
        const r1 = await this.props.gate.request("/campuses/get-all", {data})
        this.isLoading = false
        if (r1.success) {
            this.campuses = r1.data.map((el: api_response.Campus) => {
                return {value: el.id, label: el.name}
            })
        }
    }

    private onChange(e) {
        let selected_ids: number[] = []

        if (e) {
            selected_ids = e.map((el) => {
                return el.value
            })
        }

        this.props.onChange(selected_ids)
    }

    public render() {
        return (
            <Select
                isMulti
                isLoading={this.isLoading}
                options={this.campuses}
                value={this.selectedCampuses}
                onChange={this.onChange.bind(this)}
                placeholder="Campus"
            />
        )
    }
}
