import React, {Component} from "react"
import styles from "./PaginatorLoadMoreButton.module.css"

interface Props {
    onLoad: () => void
    isLoading?: boolean
    totalPages: number
    page: number
}

interface State {
    isLoading: boolean
}

export class PaginatorLoadMoreButton extends Component<Props, State> {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false
        }
    }

    public componentWillReceiveProps(nextProps) {
        if (!nextProps.isLoading && this.state.isLoading) {
            this.setState({isLoading: false})
        }
    }

    private onClick() {
        this.setState({isLoading: true}, () => {
            this.props.onLoad()
        })
    }

    public render() {
        if (this.props.totalPages <= this.props.page) {
            return null
        }

        const css = `${styles["show-more-btn"]} ${styles["paginator__show-more"]}`

        return (
            <>
                {this.state.isLoading ? (
                    <button className={css}>Loading</button>
                ) : (
                    <button onClick={this.onClick.bind(this)} className={css}>
                        Show more
                    </button>
                )}
            </>
        )
    }
}
