import React, {Component} from "react"
import styles from "./button.module.css"
import classNames from "classnames"

interface Props {
    name?: string
    disabled?: boolean
    type?: "transparent"
    onClick: () => void
}

export class Button extends Component<Props, {}> {
    public render() {
        const {name, disabled, type} = this.props
        return (
            <button
                className={classNames(styles.button, type === "transparent" && styles.buttonTransparent)}
                onClick={this.props.onClick}
                disabled={disabled}>
                {name}
            </button>
        )
    }
}
