import React, {Component} from "react"
import styles from "./milestone-card.module.css"
import classNames from "classnames"
import {observer} from "mobx-react"
import {ReactComponent as ChartIcon} from "./parts/images/bar-chart.svg"
import {ReactComponent as ClockIcon} from "./parts/images/clock.svg"
import {ReactComponent as PenIcon} from "./parts/images/pen.svg"
import {api_response} from "lib/gate/interfaces"
import {Model} from "Model"
import {Gate} from "lib/gate/Gate"
import {Signatures} from "./parts/Signatures"
import {observable} from "mobx"
import {SignaturePopup} from "../SignaturePopup"
import {TrashButton} from "components/buttons/trash-btn"

interface Props {
    orderNumber: number
    item: api_response.Milestone
    model: Model
    gate: Gate
}

interface Popup {
    visible: boolean
    milestone: null | api_response.Milestone
}

@observer
export class MilestoneCard extends Component<Props, {}> {
    @observable
    private popup: Popup = {
        visible: false,
        milestone: null
    }

    public render() {
        const {item, model, gate, orderNumber} = this.props
        // const role = this.props.model.visitor.role
        return (
            <article className={styles.card}>
                {this.popup.visible && (
                    <SignaturePopup
                        gate={gate}
                        model={model}
                        milestone={this.popup.milestone}
                        onClose={() => {
                            this.popup.visible = false
                            this.popup.milestone = null
                        }}
                    />
                )}
                <header className={styles.imageWrapper}>
                    <div className={styles.marker}>
                        <PenIcon className={styles.markerIcon} width="13" height="13" />
                    </div>
                    <img src={require("./parts/images/goodgradesar.jpg")} alt="" className={styles.image} />
                </header>
                <section className={styles.info}>
                    <div className={styles.infoBar}>
                        <div className={classNames(styles.numberWrapper, this.getStatusStyle(item.status))}>
                            <span className={styles.number}>{orderNumber}</span>
                        </div>
                        <div className={styles.infoItem}>
                            <ClockIcon className={styles.infoIcon} width="11" height="11" />
                            <p className={styles.infoData}>{item.min_hours_attended} Hours</p>
                        </div>
                        <div className={styles.infoItem}>
                            <ChartIcon className={styles.infoIcon} width="11" height="11" />
                            <p className={styles.infoData}>Active</p>
                        </div>
                    </div>
                    <h2 className={styles.title}>{item.name}</h2>
                    <p className={styles.description}>{item.description}</p>
                </section>
                <Signatures
                    item={item}
                    model={model}
                    gate={gate}
                    onClick={(milestone: api_response.Milestone) => {
                        this.popup.visible = true
                        this.popup.milestone = milestone
                    }}
                />
                <div className={styles.iconDelWrapper}>
                    <TrashButton
                        onClick={async () => {
                            await gate.request(`/milestones/${item.id}/delete`)
                            model.loadPassport(item.user_id)
                        }}
                    />
                </div>
            </article>
        )
    }

    private getStatusStyle(status: api_response.MilestoneStatus): string {
        switch (status) {
            case "unreached":
                return styles["numberWrapper--unreached"]
            case "in progress":
                return styles["numberWrapper--inProgress"]
            case "overdue":
                return styles["numberWrapper--overdue"]
            case "completed":
                return styles["numberWrapper--completed"]
            default:
                return null
        }
    }
}
