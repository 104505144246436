import React, {Component} from "react"
import {Model} from "Model"
import {observer} from "mobx-react"
import {Page} from "components/misc/page"
import {VisitorBar} from "components/VisitorBar"
import {StudentStatuses} from "./parts/StudentStatuses"
import {Gate} from "lib/gate/Gate"
import {NavigationTab} from "components/navigation-tab"

interface Props {
    model: Model
    gate: Gate
}

@observer
export class SettingsPage extends Component<Props, {}> {
    public async componentDidMount() {}

    public render() {
        const props = this.props

        // const visitor = props.model.visitor

        // const isStaff: boolean = visitor.role === "staff" || visitor.role === "admin"

        // const path = window.location.pathname
        // const token = props.gate.getToken()

        return (
            <Page>
                <h2>Settings</h2>
                <NavigationTab token={props.gate.getToken()} activeTabKey="settings" />
                <VisitorBar model={this.props.model} gate={this.props.gate} />
                <StudentStatuses gate={this.props.gate} />
            </Page>
        )
    }
}
