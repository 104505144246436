import {Gate, GateResponse} from "./Gate"

export class GateListener {
    constructor(private gate: Gate) {
        gate.addListener((response: GateResponse) => {
            return
/*            if (!response.success) {
                if (Array.isArray(response.errors)) {
                    const message = response.errors
                        .filter((el) => {
                            if (el.data === "no_signature") {
                                return false
                            }
                            return true
                        })
                        .map((el) => {
                            return el.message
                        })
                        .join(", ")
                    alert(message)
                    return
                }

                alert("Something goes wrong..." + JSON.stringify(response))
                return
            }*/
        })
    }
}
