import React, {Component} from "react"
import styles from "./checkbox.module.css"

interface Props {
    value: boolean
    onChange: (value: boolean) => void
    disabled?: boolean
}

export class Checkbox extends Component<Props, {}> {
    private onChange(event) {
        event.stopPropagation()
        this.props.onChange(event.target.checked)
    }

    public render() {
        const {disabled, value} = this.props
        return (
            <label className={styles.wrapper}>
                <input
                    onClick={this.onChange.bind(this)}
                    checked={value}
                    type="checkbox"
                    className={styles.input}
                    disabled={disabled}
                />
                <div className={styles.inner}>
                    <div className={styles.oval}></div>
                </div>
            </label>
        )
    }
}
