import React, {Component} from "react"
import {ListItem} from "./ListItem"
import styles from "./List.module.css"
import {ItemVO} from "./ItemVO"

export interface ListProps {
    selectedIndexes: number[]
    items: ItemVO[]
    onClick: (item: ItemVO, index: number) => void
}

export class List extends Component<ListProps, {}> {
    public render() {
        return (
            <div className={styles["wrapper"]}>
                {this.props.items.map((item: ItemVO, index: number) => {
                    let selected = false
                    if (this.props.selectedIndexes.indexOf(index) !== -1) {
                        selected = true
                    }
                    return (
                        <ListItem
                            selected={selected}
                            title={item.title}
                            onClick={this.onClick.bind(this)}
                            index={index}
                            key={item.value}
                        />
                    )
                })}
            </div>
        )
    }

    private onClick(index: number) {
        this.props.onClick(this.props.items[index], index)
    }
}
