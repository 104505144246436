import * as React from "react"
import classNames from "classnames"
import styles from "./ContextButton.module.css"

interface ContextButtonItem {
    title: string
    icon?: string
    onClick: Function
}

interface Props {
    menuItems: ContextButtonItem[]
    isHorizontal?: boolean
    isRightMenuPosition?: boolean
    item: any
}

interface State {
    isOpened: boolean
    componentId: string
}

export class ContextButton extends React.Component<Props, State> {
    private outsideClickListener

    constructor(props) {
        super(props)
        this.state = {
            isOpened: false,
            componentId: ""
        }
    }

    componentDidMount(): void {
        let componentId = "subcontext-" + Math.round(Math.random() * 10000000)
        this.setState({componentId: componentId}, () => {
            this.hideOnClickOutside(document.getElementById(componentId))
        })
    }

    componentWillUnmount(): void {
        document.removeEventListener("click", this.outsideClickListener)
    }

    hideOnClickOutside(element) {
        this.outsideClickListener = (event) => {
            if (!element.contains(event.target) && this.state.isOpened) {
                this.setState({isOpened: false})
            }
        }
        document.addEventListener("click", this.outsideClickListener)
    }

    toggleMenu(e) {
        e.preventDefault()
        e.stopPropagation()
        this.setState({isOpened: !this.state.isOpened})
    }

    private onClick(item, e) {
        e.preventDefault()
        this.setState({isOpened: false}, () => {
            if (item.onClick) {
                item.onClick(this.props.item)
            }
        })
    }

    private getItem(item: ContextButtonItem, key) {
        return (
            <li className={styles["menuItem"]} key={key}>
                <div className={styles["menuLink"]} onClick={this.onClick.bind(this, item)}>
                    {item.icon ? (
                        <svg width="20" height="20" className={styles["menuIcon"]}>
                            <use xlinkHref={"#" + item.icon}></use>
                        </svg>
                    ) : null}
                    {item.title}
                </div>
            </li>
        )
    }

    render() {
        return (
            <div id={this.state.componentId} className={styles["wrapper"]}>
                <button
                    className={classNames(
                        styles["button"],
                        this.props.isHorizontal && styles["button--horizontal"],
                        this.props.isRightMenuPosition && styles["menu--right"]
                    )}
                    type="button"
                    onClick={this.toggleMenu.bind(this)}>
                    <svg width="20" height="20" className={styles["buttonIcon"]}>
                        <use xlinkHref="#3dot"></use>
                    </svg>
                </button>

                <ul className={classNames(styles["menu"], this.state.isOpened && styles["menu--open"])}>
                    {this.props.menuItems.map((item, key) => this.getItem(item, key))}
                </ul>
            </div>
        )
    }
}
