import React, {Component} from "react"
import classNames from "classnames"
import styles from "./Button.module.css"

interface Props {
    label: string
    // TODO Kill `... extends FormDataComponent<FormData>` pattern and give normal type to onClick
    onClick: any
    isLoading?: boolean
    isDisabled?: boolean
    width?: number
    height?: number
    style?: any
    classNames?: string
    title?: string
}

export class Button extends Component<Props, {}> {
    public render() {
        let css = `${styles["button"]} ${styles["blue"]}`

        if (this.props.classNames) {
            css += " " + this.props.classNames
        }

        const props = this.props
        let styleObject: any = {}
        if (props.width) {
            styleObject.width = `${props.width}px`
        }
        if (props.height) {
            styleObject.height = `${props.height}px`
        }

        if (this.props.style) {
            styleObject = this.props.style
        }

        return (
            <button
                className={classNames(css, {
                    preloader: props.isLoading,
                    disabled: props.isDisabled
                })}
                style={styleObject}
                onClick={this.props.onClick}
                title={this.props.title}>
                {this.props.children}
                {props.label}
            </button>
        )
    }
}
