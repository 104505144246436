import React, {Component} from "react"
import styles from "./HeaderCell.module.css"

export interface HeaderCellProps {
    columnId?: string
    key?
    width?: number
}
export class EmptyHeaderCell extends Component<HeaderCellProps> {
    public render() {
        let style: any = {}
        if (this.props.width) {
            style.width = this.props.width + "px"
            style.minWidth = this.props.width + "px"
        }

        return <th style={style} className={styles["cell"]} />
    }
}
