import React, {Component} from "react"
import styles from "./H1.module.css"

interface Props {
    children: string
}

export class H2 extends Component<Props, {}> {
    public render() {
        const style = {fontSize: "20px"}
        return (
            <h2 className={styles.title} style={style}>
                {this.props.children}
            </h2>
        )
    }
}
