import React, {Component} from "react"
import {route} from "./lib/functions/route"
import {Model} from "./Model"
import {observer} from "mobx-react"
import {StudentPage} from "./pages/students_studentid"
import {MilestonesPage} from "./pages/milestones"
import {SettingsPage} from "./pages/settings"
import {Dashboard} from "./pages/dashboard"
import {StudentsPage as NEWStudentsPage} from "./pages/studends"
import {getParameterByName} from "./lib/functions/getParameterByName"
import {Gate} from "./lib/gate/Gate"
import {Role, Visitor} from "./lib/gate/interfaces"
import {TempMilestonesPage} from "./pages/temp_milestones"
import {WorkspacePage} from "./pages/workspace"

interface Props {
    model: Model
    gate: Gate
}

@observer
export class App extends Component<Props, {}> {
    public render() {
        console.log(this.props.model.visitor)
        const path = this.props.model.path
        const role: Role = this.props.model.visitor.role

        const {model, gate} = this.props

        let html = <h1>404. Replace this HTML based on URL</h1>

        if (route("/students/:userid(/)*", path)) {
            let {userid} = route("/students/:userid(/)*", path)
            userid = parseInt(userid)
            if (this.allowViewUserPage(userid, model.visitor)) {
                html = <StudentPage model={model} gate={gate} user_id={userid} />
            } else {
                html = <h1>You are not allowed to open this page</h1>
            }
        }

        if (route("/prototypes(/)", path)) {
            html = <MilestonesPage model={model} gate={gate} />
        }

        if (route("/temp/milestones(/)", path)) {
            const campus_ids = this.getParamAsArrayFromQuery("campus_ids")
            const program_ids = this.getParamAsArrayFromQuery("program_ids")

            html = <TempMilestonesPage campus_ids={campus_ids} program_ids={program_ids} model={model} gate={gate} />
        }

        if (route("/settings(/)", path)) {
            html = <SettingsPage model={model} gate={gate} />
        }

        if (route("/dashboard(/)", path)) {
            html = <Dashboard model={model} gate={gate} />
        }

        if (route("/workspace(/)", path)) {
            const campus_ids = this.getParamAsArrayFromQuery("campus_ids")
            const program_ids = this.getParamAsArrayFromQuery("program_ids")

            html = <WorkspacePage campus_ids={campus_ids} program_ids={program_ids} model={model} gate={gate} />
        }

        if (route("/students(/)", path)) {
            html = <NEWStudentsPage model={model} gate={gate} />
        }

        if (role === "student" && !route("/students/:userid(/)*", path)) {
            // Allow only one URL for students
            html = <h1>401. Forbidden</h1>
        }

        return (
            <div>
                <div id="appModal" className="modal" />
                {html}
            </div>
        )
    }

    private getParamAsArrayFromQuery(param_name: string): number[] {
        let arr: number[] = []
        if (getParameterByName(param_name, window.location)) {
            arr = getParameterByName(param_name, window.location)
                .split(",")
                .map((el) => {
                    return parseInt(el)
                })
        }
        return arr
    }

    private allowViewUserPage(user_id, visitor: Visitor): boolean {
        if (visitor.role === "admin" || visitor.role === "staff") {
            return true
        }

        if (user_id === visitor.id) {
            // Student can view his own page
            return true
        }

        return false
    }
}
