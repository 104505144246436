import React, {Component} from "react"
import {observer} from "mobx-react"
import styles from "./TotalItems.module.css"
import {IList} from "../../../../lib/gate/IList"
import {Pagination} from "../../../../lib/gate/interfaces"

interface Props {
    model: IList
}

@observer
export class TotalItems extends Component<Props, {}> {
    public render() {
        const pagination: Pagination = this.props.model.pagination

        const page = pagination.page
        const pageSize = pagination.pageSize
        const totalItems = pagination.totalItems
        let from = 1,
            to = page * pageSize

        if (page > 1) {
            from = (page - 1) * pageSize
        }

        if (to > totalItems) {
            to = totalItems
        }

        return (
            <React.Fragment>
                {totalItems > 0 && (
                    <div className={styles["wrapper"]}>
                        <span className={styles["countStart"]}>{from}</span> –{" "}
                        <span className={styles["countEnd"]}>{to}</span> of{" "}
                        <span className={styles["countTotal"]}>{totalItems}</span>
                    </div>
                )}
            </React.Fragment>
        )
    }
}
