import React, {Component} from "react"
import styles from "./upload-image.module.css"
import {ReactComponent as Icon} from "./line.svg"
import {Button} from "components/buttons/button"

export class UploadImage extends Component<{}, {}> {
    public render() {
        return (
            <div className={styles.wrapper}>
                <Icon width="1140" height="994" className={styles.icon} />
                <p className={styles.title}>Drag & drop a JPEG or PNG file</p>
                <Button onClick={() => {}} name={"Browse to Upload"} />
            </div>
        )
    }
}
