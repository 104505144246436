import React, {Component} from "react"

interface Props {
    tokens: {
        student_10_token: string
        student_19667_token: string
        staff_15113_token: string
        admin_1_token: string
    }
}

export class DebugLinks extends Component<Props, {}> {
    public render() {
        const {student_19667_token, staff_15113_token, admin_1_token, student_10_token} = this.props.tokens
        return (
            <div style={{padding: "50px"}}>
                <h1>Test app links</h1>
                <a href={`/students?token=${staff_15113_token}`}>1. Staff open Students List</a>
                <br />
                <a href={`/students/19667?token=${student_19667_token}`}>2. Student (19667) open his own page</a>
                <br />
                <a href={`/students/19667?token=${admin_1_token}`}>3. Admin user (1) open student's (19667) page</a>
                <br />
                <a href={`/students/10?token=${student_10_token}`}>
                    2. Student <strong>test9</strong> (10) open his own page
                </a>
                <br />
                <a href={`/students/10?token=${admin_1_token}`}>
                    3. Admin user (1) open <strong>test9</strong> student's (10) page
                </a>
                <br />
                <br />
                <h1>Temporary Links</h1>
                <a href={`/temp/milestones?token=${staff_15113_token}`}>4. Staff user (15113) open milestones page</a>
                <br />
                <a href={`/temp/milestones?campus_ids=1,12,5&program_ids=27&token=${staff_15113_token}`}>
                    4.1 Staff user (15113) open milestones page with filter
                </a>
                <br />
            </div>
        )
    }
}
