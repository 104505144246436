import React, {Component} from "react"
import classNames from "classnames"
import styles from "./Label.module.css"

interface Props {
    text: string
    children: JSX.Element
    required?: boolean
    errorMessage?: string | string[]
    width?: string
}

export class Label extends Component<Props, {}> {
    public render() {
        let style: {width?: string} = {}
        if (this.props.width) {
            style.width = this.props.width
        }

        return (
            <label className={classNames(styles["label"])} style={style}>
                <p className={classNames(styles["title"], this.props.required && styles["required"])}>
                    {this.props.text}
                </p>

                {this.props.children}

                {this.props.errorMessage && (
                    <div className={classNames(styles["error"])}>{this.props.errorMessage}</div>
                )}
            </label>
        )
    }
}
