import React, {Component} from "react"
import styles from "./H1.module.css"

interface Props {
    children?: string
}

export class H1 extends Component<Props, {}> {
    public render() {
        return <h1 className={styles.title}>{this.props.children}</h1>
    }
}
