import React, {Component} from "react"
import {computed, observable, toJS} from "mobx"
import {Gate} from "lib/gate/Gate"
import {observer} from "mobx-react"
import {api_response} from "lib/gate/interfaces"
import Select from "react-select"

interface Props {
    gate: Gate
    campus_ids: number[]
    selected_ids: number[]
    onChange: (selected_id: number[]) => void
}
interface Item {
    value
    label: string
}

@observer
export class ProgramsSelect extends Component<Props, {}> {
    @observable
    private isLoading = false

    @observable
    private programs: Item[] = []

    @computed
    private get selectedPrograms(): Item[] {
        let arr: Item[] = []
        for (let id of this.props.selected_ids) {
            for (let el of this.programs) {
                if (el.value === id) {
                    arr.push(el)
                    break
                }
            }
        }

        return arr
    }

    public componentDidUpdate(prevProps: Readonly<Props>) {
        if (!prevProps.campus_ids || !this.props.campus_ids) {
            return
        }
        const arraysEqual = this.arraysAreEqual(prevProps.campus_ids, this.props.campus_ids)

        if (!arraysEqual) {
            this.reload()
        }
    }

    private arraysAreEqual(array1, array2): boolean {
        array1 = toJS(array1).sort()
        array2 = toJS(array2).sort()

        // Check if the arrays are the same length
        if (array1.length !== array2.length) return false

        // Check if all items exist and are in the same order
        for (var i = 0; i < array1.length; i++) {
            if (array1[i] !== array2[i]) return false
        }

        // Otherwise, return true
        return true
    }

    public async componentDidMount() {
        this.reload()
    }

    private async reload() {
        const data = {
            filter: {campus_ids: this.props.campus_ids}
        }
        this.isLoading = true
        let r = await this.props.gate.request("/majors/get-all", {data})
        this.isLoading = false
        if (r.success) {
            this.programs = r.data.map((el: api_response.Major) => {
                return {value: el.id, label: el.name}
            })

            this.onChange(this.selectedPrograms)
        }
    }

    private onChange(items: Item[]) {
        let selected_ids: number[] = []

        if (items) {
            selected_ids = items.map((el) => {
                return el.value
            })
        }

        this.props.onChange(selected_ids)
    }

    public render() {
        return (
            <Select
                isMulti
                isLoading={this.isLoading}
                options={this.programs}
                value={this.selectedPrograms}
                onChange={this.onChange.bind(this)}
                placeholder="Program"
            />
        )
    }
}
