import React, {Component} from "react"
import {Button} from "./Button"
import styles from "./TransparentButton.module.css"

interface Props {
    label: string
    // TODO Kill `... extends FormDataComponent<FormData>` pattern and give normal type to onClick
    onClick: any
    isLoading?: boolean
    isDisabled?: boolean
    width?: number
    height?: number
}

export class TransparentButton extends Component<Props, {}> {
    public render() {
        const props = this.props
        let styleObject: any = {}
        if (props.width) {
            styleObject.width = `${props.width}px`
        }
        if (props.height) {
            styleObject.height = `${props.height}px`
        }
        return (
            <Button
                label={props.label}
                onClick={this.props.onClick}
                classNames={styles["btn--transparent"]}
                isLoading={props.isLoading}
                isDisabled={props.isDisabled}
                style={styleObject}
            />
        )
    }
}
