import React, {Component} from "react"
import {GrayButton} from "../klass-lib"
import {SelectionModel} from "./SelectionModel"
import {action, computed} from "mobx"
import {observer} from "mobx-react"
import styles from "./SelectAllRow.module.css"
import {Checkbox} from "../forms/Checkbox/Checkbox"
import {IListItem} from "../../../../lib/gate/interfaces"

interface Props {
    onSelectButtons
    selectionModel: SelectionModel
    items: IListItem[]
}

@observer
export class SelectAllRow extends Component<Props, {}> {
    @computed
    private get isChecked(): boolean {
        let allIds: number[] = this.props.items.map((item) => {
            return item.id
        })

        for (let id of allIds) {
            if (!this.props.selectionModel.contains(id)) {
                // Display checked if all displayed items in selected array
                return false
            }
        }

        return true
    }

    public render() {
        return (
            <tr className="table__line">
                <td className={`${styles["table__cell"]} ${styles["table__cell--settings-select"]}`} colSpan={10}>
                    <div className={styles["table__cell-wrapper"]}>
                        <Checkbox isChecked={this.isChecked} onClick={this.onCheckbox.bind(this)} />

                        {this.props.onSelectButtons.length > 0 && (
                            <React.Fragment>
                                {this.props.onSelectButtons && (
                                    <div className={styles["table__settings-select-btn-wrapper"]}>
                                        {this.props.onSelectButtons.map((button, key) => (
                                            <GrayButton
                                                label={button.title}
                                                onClick={button.onClick.bind(this, this.props.selectionModel.all)}
                                                key={key}
                                            />
                                        ))}
                                    </div>
                                )}
                            </React.Fragment>
                        )}
                        {/* <p className="table__text table__text--darken">All 10 Users on this page selected</p> */}
                        {/* <p className="table__text table__text--blue">All 10 Users on this page selected</p> */}
                    </div>
                </td>
            </tr>
        )
    }

    @action
    private onCheckbox() {
        let allIds: number[] = this.props.items.map((item) => {
            return item.id
        })

        if (this.isChecked) {
            this.props.selectionModel.remove(allIds)
        } else {
            this.props.selectionModel.add(allIds)
        }
    }
}
