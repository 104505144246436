import React from "react"
import styles from "./h1.module.css"

interface Props {
    children?: string
}

export const H1: React.FunctionComponent<Props> = (props) => {
    return <h1 className={styles.title}>{props.children}</h1>
}
