import React, {Component} from "react"
import {observer} from "mobx-react"
import styles from "./table.module.css"
import {api_response, Sort} from "lib/gate/interfaces"
import {Line} from "./parts/line"
import {Preloader} from "./parts/preloader"
import {HeaderButton} from "./parts/header-button"
import {ArrowType} from "./parts/header-button/header-button"

interface Props {
    token: string
    items: api_response.StudentListItem[]
    isLoading: boolean
    tableStructure: Column[]
    sort: Sort
    onChange: (sort: Sort) => void
}

export interface Column {
    title: string
    id: string
    server_id: string
}

@observer
export class Table extends Component<Props, {}> {
    public render() {
        const {items, token, isLoading, tableStructure, onChange, sort} = this.props
        return (
            <div className={styles.tableWrapper}>
                {isLoading && <Preloader />}
                <table className={styles.table}>
                    <tbody>
                        <TableHeader tableStructure={tableStructure} onChange={onChange} sort={sort} />
                        {items.map((item: api_response.StudentListItem) => {
                            return <Line key={item.id} item={item} token={token} tableStructure={tableStructure} />
                        })}
                    </tbody>
                </table>
            </div>
        )
    }
}

interface TableHeaderProps {
    sort: Sort
    tableStructure: Column[]
    onChange: (sort: Sort) => void
}

class TableHeader extends React.Component<TableHeaderProps, {}> {
    public render() {
        const {tableStructure, sort} = this.props
        return (
            <tr className={styles.headerLine}>
                {tableStructure.map((column: Column) => {
                    let type: ArrowType = null
                    if (column.server_id === sort.orderBy) {
                        type = sort.order
                    }
                    return (
                        <th className={styles.headerCell} key={column.title}>
                            <HeaderButton
                                title={column.title}
                                onClick={(type: ArrowType) => {
                                    this.props.onChange({orderBy: column.server_id, order: type})
                                }}
                                type={type}
                            />
                        </th>
                    )
                })}
            </tr>
        )
    }
}
