import React, {Component} from "react"
import {action, observable, toJS} from "mobx"
import {observer} from "mobx-react"
import styles from "./Cell.module.css"
import {ContextButton} from "../../klass-lib/components/buttons/ContextButton"
import {IListItem} from "../../../../../lib/gate/interfaces"

interface Props {
    contextMenuItems: {title: string; icon: string; onClick: (item: IListItem, e?) => void}[]
    item?: IListItem
}

@observer
export class ContextMenuCell extends Component<Props> {
    @observable
    private isOpen: boolean = false

    private component: Node

    @action
    private contextClick(onClick, data, e) {
        e.preventDefault()
        e.stopPropagation()
        this.isOpen = false
        onClick(toJS(data))
    }

    @action
    private openContext(e) {
        e.preventDefault()
        e.stopPropagation()
        this.isOpen = true
    }

    @action
    private onMouseDown(e: MouseEvent) {
        if (!this.component) {
            return
        }

        if (this.component.contains(e.target as Node)) {
            return
        }

        this.isOpen = false
    }

    public async componentDidMount() {
        document.addEventListener("mousedown", this.onMouseDown.bind(this))
    }

    public componentWillUnmount() {
        document.removeEventListener("mousedown", this.onMouseDown.bind(this))
    }

    public render() {
        return (
            <td
                ref={(node) => {
                    this.component = node
                }}
                className={styles["cell"]}>
                <div className={styles["wrapper"]}>
                    <ContextButton menuItems={this.props.contextMenuItems} item={toJS(this.props.item)} />
                </div>
            </td>
        )
    }
}
