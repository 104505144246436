import React, {Component} from "react"
import styles from "./DebugBox.module.css"
import {Gate} from "lib/gate/Gate"
import {Model} from "Model"
import {observer} from "mobx-react"
import {action, observable} from "mobx"
import {api_response} from "lib/gate/interfaces"

interface Props {
    user_id: number
    gate: Gate
    model: Model
}

@observer
export class DebugBox extends Component<Props, {}> {
    @observable
    private user: api_response.User

    @observable
    private hours_attended: number

    public render() {
        const user = this.user
        const {user_id} = this.props
        if (!user) {
            return "No user"
        }
        return (
            <div className={styles.box}>
                <h2>Debug Box:</h2>
                <strong>User ID: {user_id}</strong>
                <br />
                Hours Attended:
                <input
                    type="text"
                    value={this.hours_attended}
                    onChange={(e) => {
                        console.log(e.target)
                        console.log(e.target.value)
                        this.hours_attended = parseInt(e.target.value)
                    }}
                />
                <button
                    disabled={user.hours_attended === this.hours_attended}
                    onClick={async () => {
                        await this.props.gate.request(`/users/${this.props.user_id}/edit`, {
                            data: {hours_attended: this.hours_attended}
                        })
                        this.loadUser()
                    }}>
                    Edit
                </button>
                <br />
                Campus:{user.campus_name} <br />
                Program: {user.program_name} <br />
            </div>
        )
    }

    public async componentDidMount() {
        this.loadUser()
    }

    @action
    private async loadUser() {
        const {success, data} = await this.props.gate.request(`/users/${this.props.user_id}/get`)
        this.user = data
        this.hours_attended = this.user.hours_attended
    }
}
