import React, {Component} from "react"
import styles from "./add-button.module.css"
import {ReactComponent as Icon} from "./plus.svg"

interface Props {
    disabled?: boolean
    onClick: () => void
}

export class AddButton extends Component<Props, {}> {
    public render() {
        const {disabled, onClick} = this.props
        return (
            <button className={styles.button} onClick={onClick} disabled={disabled}>
                <Icon width="50" height="50" className={styles.icon} />
            </button>
        )
    }
}
