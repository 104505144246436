import React, {Component} from "react"
import {computed, observable} from "mobx"
import {observer} from "mobx-react"
import Select from "react-select"
import {Gate} from "lib/gate/Gate"
import {api_response, MilestonesFilter} from "lib/gate/interfaces"
import styles from "./filter.module.css"
import {StringButton} from "components/buttons/string-button"

interface Props {
    campus_ids: number[]
    program_ids: number[]
    gate: Gate
    onChange: (filter: MilestonesFilter) => void
}

interface Item<V = any> {
    value: V
    label: string
}

@observer
export class Filter extends Component<Props, {}> {
    @observable
    private readonly defaultFilter: MilestonesFilter = {
        text: "",
        statuses: [],
        campus_ids: [],
        program_ids: [],
        prototype_ids: []
    }

    @observable
    private filter: MilestonesFilter = Object.assign({}, this.defaultFilter)

    @observable
    private campuses: Item[] = []

    @observable
    private programs: Item[] = []

    @observable
    private statuses: Item<api_response.MilestoneStatus>[] = [
        {value: "unreached", label: "Unreached"},
        {value: "in progress", label: "In progress"},
        {value: "overdue", label: "Overdue"},
        {value: "completed", label: "Completed"}
    ]

    @observable
    private prototypes: Item[] = []

    @computed
    private get selectedCampuses(): Item[] {
        let arr: Item[] = []
        for (let id of this.filter.campus_ids) {
            for (let el of this.campuses) {
                if (el.value === id) {
                    arr.push(el)
                    break
                }
            }
        }

        return arr
    }

    @computed
    private get selectedMilestones(): Item[] {
        let arr: Item[] = []
        console.log("filter", this.filter)

        for (let id of this.filter.prototype_ids) {
            for (let el of this.prototypes) {
                if (el.value === id) {
                    arr.push(el)
                    break
                }
            }
        }

        return arr
    }

    @computed
    private get selectedPrograms(): Item[] {
        let arr: Item[] = []
        for (let id of this.filter.program_ids) {
            for (let el of this.programs) {
                if (el.value === id) {
                    arr.push(el)
                    break
                }
            }
        }

        return arr
    }

    @computed
    private get selectedStatuses(): Item[] {
        let arr: Item[] = []
        for (let id of this.filter.statuses) {
            for (let el of this.statuses) {
                if (el.value === id) {
                    arr.push(el)
                    break
                }
            }
        }
        return arr
    }

    constructor(props: Props) {
        super(props)

        this.filter.campus_ids = props.campus_ids
        this.filter.program_ids = props.program_ids
    }

    public async componentDidMount() {
        const data = {
            sort: {
                orderBy: "created_at",
                order: "ASC"
            },
            filter: {},
            pagination: {
                page: 1,
                pageSize: 100
            }
        }

        const prototypesData = {
            sort: {
                orderBy: "name",
                order: "ASC"
            },
            filter: {},
            pagination: {
                page: 1,
                pageSize: 100
            }
        }

        const [r1, r2] = await Promise.all([
            this.props.gate.request("/campuses/get-all", {data}),
            this.props.gate.request("/prototypes/get-all", {data: prototypesData})
        ])
        if (r1.success) {
            this.campuses = r1.data.map((el: api_response.Campus) => {
                return {value: el.id, label: el.name}
            })
        }

        if (r2.success) {
            this.prototypes = r2.data.map((el: api_response.Prototype) => {
                return {value: el.id, label: el.name}
            })
        }

        this.reloadPrograms()
    }

    private async reloadPrograms() {
        const data = {
            filter: {campus_ids: this.filter.campus_ids}
        }
        let r = await this.props.gate.request("/majors/get-all", {data})
        if (r.success) {
            this.programs = r.data.map((el: api_response.Major) => {
                return {value: el.id, label: el.name}
            })
        }
    }

    public render() {
        return (
            <div className={styles.wrapper}>
                {/*<label className={styles.label}>
                    Search:
                    <input
                        className={styles.search}
                        type="text"
                        onChange={this.onText.bind(this)}
                        value={this.filter.text}
                    />
                </label>*/}
                <label className={styles.label}>
                    <div className={styles.input}>
                        <Select
                            isMulti
                            value={this.selectedStatuses}
                            options={this.statuses}
                            onChange={this.onStatusSelect.bind(this)}
                            placeholder="Status"
                        />
                    </div>
                </label>
                <label className={styles.label}>
                    <div className={styles.input}>
                        <Select
                            isMulti
                            options={this.campuses}
                            value={this.selectedCampuses}
                            onChange={this.onCampusSelect.bind(this)}
                            placeholder="Campus"
                        />
                    </div>
                </label>
                <label className={styles.label}>
                    <div className={styles.input}>
                        <Select
                            isMulti
                            options={this.programs}
                            value={this.selectedPrograms}
                            onChange={this.onProgramSelect.bind(this)}
                            placeholder="Program"
                        />
                    </div>
                </label>
                <label className={styles.label}>
                    <div className={styles.input}>
                        <Select
                            isMulti
                            value={this.selectedMilestones}
                            options={this.prototypes}
                            onChange={this.onPrototypeSelect.bind(this)}
                            placeholder="Milestone"
                        />
                    </div>
                </label>

                <div className={styles.buttonWrapper}>
                    <StringButton
                        onClick={() => {
                            this.onResetFilter()
                        }}
                        name={"Reset Filters"}
                    />
                </div>
            </div>
        )
    }

    private onResetFilter() {
        this.filter = Object.assign({}, this.defaultFilter)
        console.log("filter", this.defaultFilter)
        this.props.onChange(this.filter)
    }

    private onStatusSelect(e) {
        this.filter.statuses = []

        if (e) {
            this.filter.statuses = e.map((el) => {
                return el.value
            })
        }

        this.props.onChange(this.filter)
    }

    private onCampusSelect(e) {
        this.filter.campus_ids = []

        if (e) {
            this.filter.campus_ids = e.map((el) => {
                return el.value
            })
        }

        this.props.onChange(this.filter)
        this.reloadPrograms()
    }

    private onProgramSelect(e) {
        this.filter.program_ids = []

        if (e) {
            this.filter.program_ids = e.map((el) => {
                return el.value
            })
        }

        this.props.onChange(this.filter)
    }

    private onPrototypeSelect(e) {
        this.filter.prototype_ids = []

        if (e) {
            this.filter.prototype_ids = e.map((el) => {
                return el.value
            })
        }

        this.props.onChange(this.filter)
    }

    private onText(event) {
        this.filter.text = event.target.value
        this.props.onChange(this.filter)
    }
}
