import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import {App} from "./App"
import {GateListener} from "./lib/gate/GateListener"
import {Model} from "./Model"
import {createBrowserHistory} from "history"
import {getParameterByName} from "./lib/functions/getParameterByName"
import {Gate} from "./lib/gate/Gate"
import {Router} from "react-router"
import {IndexPage} from "./pages/index"

const token = getParameterByName("token")

const gate = new Gate()
new GateListener(gate)
const history = createBrowserHistory()
const model = new Model(gate, history)

main()

async function main() {
    if (!token) {
        showIndex()
        return
    }
    gate.setToken(token)

    await Promise.all([model.loadMe(), model.loadAppSettings()])

    ReactDOM.render(
        <Router history={history}>
            <App model={model} gate={gate} />
        </Router>,
        document.getElementById("root")
    )
}

function showIndex() {
    ReactDOM.render(<IndexPage gate={gate} />, document.getElementById("root"))
}
