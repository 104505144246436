import classNames from "classnames"
import React, {Component} from "react"
import styles from "./pagination.module.css"

interface Props {
    totalPages: number
    page: number
    isLoading?: boolean
    onChange: (page) => void
}

export class Pagination extends Component<Props, {}> {
    private range = 4 // Paginator step range

    constructor(props) {
        super(props)
        this.onChange = this.onChange.bind(this)
    }

    private onChange(page, e) {
        e.preventDefault()
        this.props.onChange(page)
    }

    private getItem(page) {
        return (
            <button
                onClick={this.onChange.bind(this, page)}
                className={classNames(styles.button, page === this.props.page && styles.buttonActive)}
                key={page}>
                <span className={styles.text}>{page}</span>
            </button>
        )
    }

    render() {
        const {totalPages, page, isLoading} = this.props
        const {range, onChange} = this

        /**
         * We need to show paginator, if pages less then 1,
         */
        if (totalPages <= 1 || isLoading) {
            return <nav className={styles.wrapper}></nav>
        }

        const list: JSX.Element[] = []
        let from: number = page - range <= 0 ? 1 : page - range,
            to: number = from + range * 2

        if (to > totalPages) to = totalPages

        if (page === to) {
            from = to - range * 2
            if (from < 1) from = 1
        }

        for (let i = from; i <= to; i++) {
            list.push(this.getItem(i))
        }

        return (
            <nav className={styles.wrapper}>
                {page > 1 && (
                    <button
                        onClick={(e) => onChange(1, e)}
                        className={classNames(styles.button, styles.buttonLeft)}/>
                )}

                {list}

                {page !== totalPages && (
                    <button
                        onClick={(e) => onChange(totalPages, e)}
                        className={classNames(styles.button, styles.buttonRight)}/>
                )}
            </nav>
        )
    }
}
