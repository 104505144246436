import React, {Component} from "react"
import styles from "./student-page.module.css"
import {Model} from "Model"
import {observer} from "mobx-react"
import {VisitorBar} from "components/VisitorBar"
import {Gate} from "lib/gate/Gate"
import {api_response, User} from "lib/gate/interfaces"
import {Page} from "components/misc/page"
import {H1} from "components/misc/h1"
import {ReactComponent as Icon} from "./parts/images/fill.svg"
import {MilestoneCard} from "./parts/milestone-card"
import {DebugBox} from "./parts/debug-box"
import {BackButton} from "./parts/BackButton"

interface Props {
    user_id: number
    model: Model
    gate: Gate
}

@observer
export class StudentPage extends Component<Props, {}> {
    public async componentDidMount() {
        this.props.model.user = this.props.model.user || ({id: this.props.user_id} as User)
        this.props.model.loadUser(this.props.user_id)

        this.props.model.loadPassport(this.props.user_id)
    }

    public render() {
        const props = this.props
        const user = props.model.user
        const {user_id, gate, model} = this.props

        if (!user) {
            return "Loading ..."
        }

        // const isStaff: boolean = visitor.role === "staff" || visitor.role === "admin"

        const token = props.gate.getToken()
        const backURL = `/students?token=${token}`

        return (
            <Page htmlTitle="Student Milestones">
                <VisitorBar model={model} gate={gate} />
                {process.env.REACT_APP_DEBUG && <DebugBox user_id={user_id} gate={gate} model={model} />}
                <header className={styles.header}>
                    <BackButton url={backURL} />
                    <H1>{`${user.first_name} ${user.last_name} (${user.hours_attended || 0})`}</H1>
                </header>
                <main>
                    <div className={styles.mainTitle}>
                        <Icon className={styles.titleIcon} width="24" height="24" />
                        <h2 className={styles.title}>Student Milestones</h2>
                    </div>
                    <section className={styles.cardWrapper}>
                        {this.props.model.passport.map((item: api_response.Milestone, index) => {
                            return (
                                <MilestoneCard
                                    orderNumber={index + 1}
                                    key={item.id}
                                    item={item}
                                    model={this.props.model}
                                    gate={this.props.gate}
                                />
                            )
                        })}
                    </section>
                </main>
            </Page>
        )
    }
}
