import React, {Component} from "react"
import Select from "react-select"
import {observer} from "mobx-react"
import {computed} from "mobx"

interface Props {
    days: number[]
    onChange: (days: number[]) => void
}

interface Item {
    label: string
    value
}

const options = [
    {label: "1 Day Before", value: 1},
    {label: "5 Days Before", value: 5}
]

@observer
export class DaysBefore extends Component<Props, {}> {
    @computed
    private get values(): Item[] {
        let arr: Item[] = []
        for (let day of this.props.days) {
            for (let el of options) {
                if (el.value === day) {
                    arr.push(el)
                    break
                }
            }
        }

        return arr
    }
    public render() {
        return (
            <Select
                isMulti
                value={this.values}
                options={options}
                placeholder="Select days"
                onChange={(values: Item[]) => {
                    const arr = values.map((el) => {
                        return parseInt(el.value)
                    })
                    this.props.onChange(arr)
                }}
            />
        )
    }
}
