import React, {Component} from "react"
import {api_response} from "lib/gate/interfaces"
import styles from "./line.module.css"
import {observer} from "mobx-react"
import classNames from "classnames"
import {Column} from "../../table"
import moment from "moment"
import {Icons} from "../icons"
import {Link} from "react-router-dom"

interface Props {
    item: api_response.Milestone
    token: string
    tableStructure: Column[]
}

@observer
export class Line extends Component<Props, {}> {
    public render() {
        const {tableStructure} = this.props
        return (
            <React.Fragment>
                <tr>
                    {tableStructure.map((column: Column) => {
                        return this.getCell(column)
                    })}
                </tr>
            </React.Fragment>
        )
    }

    private getCell(c: Column): JSX.Element {
        const {item, token} = this.props

        switch (c.id) {
            case "status":
                return <StatusCell key={c.title} item={item} />

            case "full_name":
                return <NameCell key={c.title} item={item} />

            case "created_at":
                return <DateCell key={c.title} column={c} item={item} />

            case "reached_at":
                return <DateCell key={c.title} column={c} item={item} />

            case "id":
                return <IdCell key={c.title} column={c} item={item} />

            case "milestone_name":
                return <MilestoneCell key={c.title} token={token} item={item} />

            default:
                return <DefaultCell key={c.title} column={c} item={item} />
        }
    }
}

function StatusCell({item}: {item: api_response.Milestone}) {
    return (
        <td className={classNames(styles.cell, styles.cellMilestone)}>
            <div className={styles.milestoneWrapper}>
                <Icons name={item["status"]} />
                <span> {item["status"]}</span>
            </div>
        </td>
    )
}

interface DefaultCellProps {
    column: Column
    item: api_response.Milestone
}

function DefaultCell({column, item}: DefaultCellProps) {
    return <td className={styles.cell}>{item[column.id]}</td>
}

interface MilestoneCellProps {
    item: api_response.Milestone
    token: string
}

function MilestoneCell({item, token}: MilestoneCellProps) {
    return (
        <td className={classNames(styles.cell)}>
            {console.log("ITEM!!!", item)}

            <Link className={styles.link} to={`/students/${item["user_id"]}?token=${token}`}>
                {item.name}
            </Link>
        </td>
    )
}

function IdCell({column, item}: DefaultCellProps) {
    return <td className={classNames(styles.cell, styles.cellPl)}>{item[column.id]}</td>
}

function DateCell({column, item}: DefaultCellProps) {
    let date: string
    if (item[column.id]) {
        date = moment.utc(item[column.id]).format("MMMM DD, YYYY")
    } else date = "n/a"
    return <td className={styles.cell}>{date}</td>
}

function NameCell({item}: {item: api_response.Milestone}) {
    return <td className={styles.cell}>{`${item["first_name"]} ${item["last_name"]}`}</td>
}
