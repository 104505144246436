import React, {Component, ReactElement} from "react"
import styles from "./CoreTable.module.css"
import {HeaderCellProps} from "./HeaderCell"
import {SelectAllRow} from "./SelectAllRow"
import {SelectionModel} from "./SelectionModel"
import {CheckboxCell} from "./cells/CheckboxCell"
import {EmptyHeaderCell} from "./EmptyHeaderCell"
import {IListItem} from "../../../../lib/gate/interfaces"

interface Props {
    items: IListItem[]
    children: JSX.Element[]
    visibleColumnIds: string[]
    /**
     * Parameter name to order list by.
     * Example: `first_name`
     */
    orderBy: string
    order: "ASC" | "DESC"
    onHeaderClick: (columnId: string) => void
    onRowClick: (item: IListItem) => void
    selectionModel?: SelectionModel
}

export class CoreTable extends Component<Props> {
    private getHeadersRow(): JSX.Element[] {
        let arr: JSX.Element[] = []

        if (this.props.selectionModel) {
            arr.push(<EmptyHeaderCell width={40} key="checkbox_cell" />)
        }

        for (let id of this.props.visibleColumnIds) {
            arr.push(this.getColumnHeader(id))
        }
        return arr
    }

    private getCellsRow(item: IListItem): JSX.Element {
        let arr: JSX.Element[] = []

        if (this.props.selectionModel) {
            arr.push(<CheckboxCell item={item} selectionModel={this.props.selectionModel} key="checkbox_cell" />)
        }

        for (let id of this.props.visibleColumnIds) {
            let cell = this.getColumnCell(item, id)
            arr.push(cell)
        }
        return (
            <tr className={styles["line"]} key={item.id} onClick={this.onRowClick.bind(this, item)}>
                {arr}
            </tr>
        )
    }

    private onRowClick(item: IListItem) {
        this.props.onRowClick(item)
    }

    private getColumnHeader(id: string): ReactElement<HeaderCellProps> {
        for (let element of this.props.children) {
            if (element.props.id === id) {
                let additionalProps: Partial<HeaderCellProps> = {
                    key: id,
                    orderBy: this.props.orderBy,
                    order: this.props.order,
                    columnId: id,
                    onClick: this.onHeaderClick.bind(this)
                }

                return React.cloneElement(element.props.header, additionalProps)
            }
        }
    }

    private onHeaderClick(columnId: string) {
        this.props.onHeaderClick(columnId)
    }

    private getColumnCell(item: IListItem, id: string): JSX.Element {
        for (let element of this.props.children) {
            if (element.props.id === id) {
                return React.cloneElement(element.props.cell, {key: id, item, itemKey: id})
            }
        }
    }

    public render() {
        return (
            <div className={styles["wrapper"]}>
                <table className={styles["table"]}>
                    <thead>
                        <tr>{this.getHeadersRow()}</tr>
                    </thead>
                    <tbody>
                        {this.props.selectionModel && (
                            <SelectAllRow
                                selectionModel={this.props.selectionModel}
                                items={this.props.items}
                                onSelectButtons={
                                    [
                                        // {
                                        //     title: "Delete",
                                        //     onClick: () => {}
                                        // }
                                    ]
                                }
                            />
                        )}

                        {this.props.items.map((item, index: number) => {
                            return this.getCellsRow(item)
                        })}
                    </tbody>
                </table>
            </div>
        )
    }
}
