import React, {Component} from "react"
import styles from "./Form.module.css"

interface Props {
    children: JSX.Element | JSX.Element[]
}

export class Form extends Component<Props, {}> {
    public render() {
        return <div className={styles.form}>{this.props.children}</div>
    }
}
