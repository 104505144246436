import * as React from "react"
import {PaginatorSize} from "./PaginatorSize"
import {PaginatorNumbers} from "./PaginatorNumbers/PaginatorNumbers"
import {PaginatorLoadMoreButton} from "./PaginatorLoadMoreButton"
import {observer} from "mobx-react"
import styles from "./Paginator.module.css"
import {IList} from "../../../../lib/gate/IList"

interface Props {
    model: IList<any>
}

interface State {}

@observer
export class Paginator extends React.Component<Props, State> {
    private model

    constructor(props) {
        super(props)
        this.model = this.props.model
    }

    async loadMore() {
        await this.model.loadMore()
    }

    async changePage(page) {
        await this.model.setPage(page)
        await this.model.load()
    }

    async changeSize(size) {
        await this.model.setPageSize(size)
        await this.model.setPage(1)
        await this.model.load()
    }

    render() {
        return (
            <div>
                <div className={styles.paginator}>
                    <PaginatorLoadMoreButton
                        onLoad={this.loadMore.bind(this)}
                        isLoading={this.model.isLoading}
                        totalPages={this.model.pagination.totalPages}
                        page={this.model.pagination.page}
                    />

                    <PaginatorNumbers
                        totalPages={this.model.pagination.totalPages}
                        page={this.model.pagination.page}
                        isLoading={this.model.isLoading}
                        onChange={this.changePage.bind(this)}
                    />

                    <PaginatorSize
                        defaultPageSize={this.model.pagination.pageSize}
                        onChange={this.changeSize.bind(this)}
                    />
                </div>
            </div>
        )
    }
}
