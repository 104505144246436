import React, {Component} from "react"
import {Model} from "../../Model"
import {observer} from "mobx-react"
import {observable, toJS} from "mobx"
import {Page} from "../../components/misc/page"
import {MilestonesTable} from "./parts/MilestonesTable"
import {Paginator} from "./temp/paginator/Paginator"
import {Filter} from "./parts/Filter"
import {VisitorBar} from "../../components/VisitorBar"
import {Gate} from "../../lib/gate/Gate"
import {List} from "../../lib/gate/List"
import {IList} from "../../lib/gate/IList"
import {api_response, MilestonesFilter} from "../../lib/gate/interfaces"

interface Props {
    campus_ids: number[]
    program_ids: number[]
    model: Model
    gate: Gate
}

@observer
export class TempMilestonesPage extends Component<Props, {}> {
    @observable
    private milestones: api_response.Milestone[] = []

    private list: IList

    constructor(props) {
        super(props)
        this.list = new List(this.props.gate, "/milestones")
        this.list.setSort({
            orderBy: "created_at",
            order: "ASC"
        })
    }

    public async componentDidMount() {}

    public render() {
        const props = this.props

        const token = props.gate.getToken()

        return (
            <Page>
                <h1>Milestones</h1>
                <VisitorBar model={this.props.model} gate={this.props.gate} />
                <Filter
                    campus_ids={this.props.campus_ids}
                    program_ids={this.props.program_ids}
                    onChange={this.onFilter.bind(this)}
                    gate={this.props.gate}
                />
                <MilestonesTable
                    list={this.list}
                    onRowClick={(item) => {
                        console.log(item)
                        // @ts-ignore
                        const user_id = item.user_id
                        this.props.model.history.push(`/users/${user_id}?token=${token}`)
                    }}
                />
                <Paginator model={this.list} />
            </Page>
        )
    }

    private onFilter(filter: MilestonesFilter) {
        console.log(toJS(filter))
        this.list.setFilter(filter)
        this.list.setPage(1)
        this.list.load()
    }

    private async onAddClick() {
        const data = {
            json_data: {}
        }
        await this.props.gate.request("/packs/add", {data})
    }
}
