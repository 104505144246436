import React, {Component} from "react"
import {Model} from "Model"
import {observer} from "mobx-react"
import {DashboardCard} from "./parts/DashboardCard"
import {observable} from "mobx"
import {Gate} from "lib/gate/Gate"
import {api_response} from "lib/gate/interfaces"
import styles from "./dashboard.module.css"
import {NavigationTab} from "components/navigation-tab"
import {H1} from "components/misc/h1"
import {Filter} from "./parts/filter"
import {Page} from "components/misc/page"

interface Props {
    model: Model
    gate: Gate
}

@observer
export class Dashboard extends Component<Props, {}> {
    @observable
    private dashboard: api_response.DashboardItem[] = []

    public async componentDidMount() {
        const r = await this.props.gate.request("/dashboard/get")
        this.dashboard = r.data
    }
    public render() {
        const {gate} = this.props
        return (
            <Page htmlTitle="Dashboard">
                <header className={styles.header}>
                    <H1>Dashboard</H1>
                </header>
                <NavigationTab token={gate.getToken()} activeTabKey="dashboard" />
                <Filter />
                <main className={styles.dashboard}>
                    {this.dashboard.map((item) => {
                        return <DashboardCard item={item} key={item.location_id} />
                    })}
                </main>
            </Page>
        )
    }
}
