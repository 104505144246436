import React, {Component, SyntheticEvent} from "react"
import classNames from "classnames"
import styles from "./InputText.module.css"

interface Props {
    value?: string
    className?: string
    placeholder?: string
    onChange?: (newValue: string) => void
}

export class InputText extends Component<Props, {}> {
    public render() {
        return (
            <input
                className={classNames(styles["input"], styles[this.props.className])}
                type="text"
                value={this.props.value || ""}
                placeholder={this.props.placeholder}
                onChange={this.onChange.bind(this)}
            />
        )
    }

    private onChange(e: SyntheticEvent) {
        // @ts-ignore
        this.props.onChange(e.target.value)
    }
}
