import {action, observable, toJS} from "mobx"

import {Filter, IListItem, Pagination, Sort} from "./interfaces"
import {IList, LoadOption} from "./IList"
import {Gate, GateResponse} from "./Gate"

export class List<F = Filter, ITEM extends IListItem = IListItem> implements IList<F, IListItem> {
    constructor(protected gate: Gate, private baseUrl: string) {}

    @observable items: ITEM[] = []

    @observable sort: Sort = {
        orderBy: "created_at",
        order: "ASC"
    }

    @observable filter: F = {} as F

    @observable pagination: Pagination = {
        page: 1,
        pageSize: 20,
        totalItems: 0,
        totalPages: 0
    }

    @observable loadOption: LoadOption
    @observable isLoading: boolean = false

    public async add(data): Promise<GateResponse> {
        let r = await this.gate.request(`${this.baseUrl}/add`, {
            data
        })

        if (r.success) {
            this.setPage(1)
            await this.load()
        }

        return r
    }

    @action
    private async loadUsingMode(option: LoadOption): Promise<GateResponse> {
        this.loadOption = option
        this.isLoading = true
        let r = await this.gate.request(`${this.baseUrl}/get-all`, {
            data: {
                sort: toJS(this.sort),
                filter: toJS(this.filter),
                pagination: {page: this.pagination.page, pageSize: this.pagination.pageSize}
            }
        })

        this.onLoad(r, option)

        return r
    }

    @action
    async delete(ids: number[]): Promise<GateResponse> {
        let r = await this.gate.request(`${this.baseUrl}/delete`, {
            data: ids
        })
        return r
    }

    @action
    public updateFilter(filter: Partial<F>) {
        for (let key in filter) {
            this.filter[key] = filter[key]
        }
    }

    @action
    public setFilter(filter: F) {
        this.filter = filter
    }

    @action
    public setSort(sort: Sort) {
        this.sort = sort
    }

    @action
    public setPage(page: number) {
        this.pagination.page = page
    }

    @action
    public setPageSize(pageSize: number) {
        this.pagination.pageSize = pageSize
    }

    @action
    public loadMore(): Promise<GateResponse> {
        if (this.pagination.page < this.pagination.totalPages) {
            this.pagination.page++
            return this.loadUsingMode("more")
        }
    }

    @action
    public async load(): Promise<GateResponse> {
        return await this.loadUsingMode("normal")
    }

    @action
    private onLoad(r: GateResponse, option: LoadOption) {
        this.isLoading = false

        if (r.success) {
            if (option === "more") {
                this.items = this.items.concat(r.data)
            } else {
                this.items = r.data
            }

            this.sort = r.meta.sort
            this.filter = r.meta.filter
            this.pagination = r.meta.pagination
        }
    }

    public async editItem(id: number, data): Promise<GateResponse> {
        return await this.gate.request(`${this.baseUrl}/${id}/edit`, {data})
    }

    public async loadItem<D = any>(id: number): Promise<GateResponse<D>> {
        return await this.gate.request(`${this.baseUrl}/${id}/get`, {})
    }
}
