import React, {Component} from "react"
import styles from "./Checkbox.module.css"
import {ReactComponent as Icon} from "./checked.svg"

interface Props {
    label?: string
    isChecked: boolean
    onClick: (e?) => void
}

export class Checkbox extends Component<Props, {}> {
    private onClick(e) {
        e.preventDefault()
        e.stopPropagation()
        this.props.onClick()
    }

    public render() {
        return (
            <label className={styles.checkBox} onClick={this.onClick.bind(this)}>
                <input className={styles.input} type="checkbox" checked={this.props.isChecked} />
                <Icon width="20" height="20" className={styles.icon} />
                {this.props.label && <p className={styles.text}>{this.props.label}</p>}
            </label>
        )
    }
}
