import React, {Component} from "react"
import {Column} from "../../temp/klass-table/Column"
import {HeaderCell} from "../../temp/klass-table/HeaderCell"
import {Cell} from "../../temp/klass-table/cells/Cell"
import {CustomCell} from "../../temp/klass-table/cells/CustomCell"
import {observer} from "mobx-react"
import {Table} from "../../temp/klass-table/Table"
import {ContextMenuCell} from "../../temp/klass-table/cells/ContextMenuCell"
import {EmptyHeaderCell} from "../../temp/klass-table/EmptyHeaderCell"
import {SelectionModel} from "../../temp/klass-table/SelectionModel"
import {IList} from "lib/gate/IList"
import {Filter, IListItem} from "lib/gate/interfaces"

interface Props {
    list: IList<Filter, IListItem>
    onRowClick: (item: IListItem) => void
}

@observer
export class MilestonesTable extends Component<Props> {
    private selectedItems: SelectionModel

    constructor(props) {
        super(props)
        this.selectedItems = new SelectionModel()
        this.selectedItems.add([1, 2, 3])
    }
    public render() {
        return (
            <Table
                list={this.props.list}
                localStorageId="MilestonesTable"
                onRowClick={this.props.onRowClick}
                selectedItems={this.selectedItems}>
                <Column id="id" header={<HeaderCell title="ID" />} cell={<Cell itemKey="id" />} />
                <Column id="user_id" header={<HeaderCell title="User ID" />} cell={<Cell itemKey="user_id" />} />
                <Column
                    id="first_name"
                    header={<HeaderCell title="First Name" />}
                    cell={<Cell itemKey="first_name" />}
                />
                <Column id="last_name" header={<HeaderCell title="Last Name" />} cell={<Cell itemKey="last_name" />} />
                <Column id="name" header={<HeaderCell title="Name" />} cell={<Cell itemKey="name" />} />
                <Column id="status" header={<HeaderCell title="Status" />} cell={<Cell itemKey="status" />} />
                <Column
                    id="reached_at"
                    header={<HeaderCell title="Reached At" />}
                    cell={<Cell itemKey="reached_at" />}
                />
                <Column id="custom" header={<HeaderCell title="" />} cell={<CustomCell />} />
                <Column
                    id="custom2"
                    header={<EmptyHeaderCell width={20} />}
                    cell={
                        <ContextMenuCell
                            contextMenuItems={[
                                {
                                    title: "menu item 1",
                                    icon: "pencil",
                                    onClick: (e) => {
                                        console.log("Menu item 1 clicked", e)
                                    }
                                },
                                {
                                    title: "menu item 2",
                                    icon: "trash",
                                    onClick: (e) => {
                                        console.log("Menu item 2 clicked", e)
                                    }
                                },
                                {
                                    title: "menu item 3",
                                    icon: "trash",
                                    onClick: (e) => {
                                        console.log("Menu item 3 clicked", e)
                                    }
                                }
                            ]}
                        />
                    }
                />
            </Table>
        )
    }
}
