import React, {Component} from "react"
import styles from "./sub-table.module.css"
import {Icons} from "./icons"
import {api_response} from "lib/gate/interfaces"
import {Link} from "react-router-dom"

interface Props {
    item: api_response.Milestone[]
    link: string
}

export class SubTable extends Component<Props, {}> {
    public render() {
        const {item, link} = this.props
        return (
            <React.Fragment>
                <table className={styles.table}>
                    <tbody>
                        {item.map((m: api_response.Milestone) => {
                            return <Row m={m} key={m.id} link={link} />
                        })}
                    </tbody>
                </table>
            </React.Fragment>
        )
    }
}

interface RowProps {
    m: api_response.Milestone
    link: string
}

function Row({m, link}: RowProps) {
    return (
        <tr>
            <td className={styles.name}>
                <Link className={styles.link} to={link}>
                    {m.name}
                </Link>
            </td>
            <td className={styles.status}>
                <div className={styles.statusWrapper}>
                    <Icons name={m.status} />
                    <span className={styles.statusName}>{m.status}</span>
                </div>
            </td>
        </tr>
    )
}
