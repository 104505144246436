import React, {Component} from "react"
import {CoreTable} from "./CoreTable"
import {TotalItems} from "./TotalItems"
import {observer} from "mobx-react"
import {MultiSelectFilter} from "../filters/MultiSelectFilter"
import {Column} from "./Column"
import {action, observable} from "mobx"
import styles from "./MiddleTable.module.css"
import {SelectionModel} from "./SelectionModel"
import {DropdownItem} from "../filters/interfaces"
import {IList} from "../../../../lib/gate/IList"
import {IListItem} from "../../../../lib/gate/interfaces"

interface Props {
    list: IList
    children: JSX.Element[]
    localStorageId?: string
    onRowClick: (item: IListItem) => void
    selectionModel?: SelectionModel
}

@observer
export class MiddleTable extends Component<Props> {
    @observable
    private visibleColumnIds: string[] = []

    private initialVisibleColumnIds: string[]

    public componentDidMount() {
        this.visibleColumnIds = this.getAllColumns().map((item: DropdownItem) => {
            return item.value
        })
        this.initialVisibleColumnIds = this.visibleColumnIds

        this.restoreSettingsFromLocalStorage()

        this.makeSureSortOrderAlwaysVisible()
    }

    private makeSureSortOrderAlwaysVisible() {
        if (this.visibleColumnIds.indexOf(this.props.list.sort.orderBy) === -1) {
            // Order By Column not visible.
            // Make sort by first column
            this.onHeaderClick(this.visibleColumnIds[0])
        }
    }

    @action
    private restoreSettingsFromLocalStorage() {
        if (this.props.localStorageId) {
            let s = localStorage.getItem(this.props.localStorageId)
            if (!s) {
                return
            }

            let settings = JSON.parse(s)
            if (!settings.visibleColumnIds) {
                return
            }

            if (settings.visibleColumnIds.length > 0) {
                this.visibleColumnIds = settings.visibleColumnIds
            }
            this.props.list.setSort({orderBy: settings.orderBy, order: settings.order})
            this.props.list.load()
        }
    }

    private saveSettingsToLocalStorage() {
        if (this.props.localStorageId) {
            let settings = {
                visibleColumnIds: this.visibleColumnIds,
                orderBy: this.props.list.sort.orderBy,
                order: this.props.list.sort.order
            }
            localStorage.setItem(this.props.localStorageId, JSON.stringify(settings))
        }
    }

    public componentWillUnmount() {
        this.saveSettingsToLocalStorage()
    }

    public render() {
        const props = this.props
        return (
            <>
                <div className={styles["headerWrapper"]}>
                    <TotalItems model={props.list} />

                    <div className={styles["buttonWrapper"]}>
                        <MultiSelectFilter
                            items={this.getAllColumns()}
                            onChange={this.toggleColumns.bind(this)}
                            selected={this.visibleColumnIds}
                            label="Columns"
                            onReset={this.resetColumns.bind(this)}
                            noCurrentLabel
                            right
                        />
                    </div>
                </div>
                <CoreTable
                    items={props.list.items}
                    visibleColumnIds={this.visibleColumnIds}
                    order={props.list.sort.order}
                    orderBy={props.list.sort.orderBy}
                    onHeaderClick={this.onHeaderClick.bind(this)}
                    onRowClick={this.props.onRowClick}
                    selectionModel={this.props.selectionModel}>
                    {props.children}
                </CoreTable>
                <div className={styles["footerWrapper"]}>
                    <TotalItems model={this.props.list} />
                </div>
            </>
        )
    }

    private onHeaderClick(columnId: string) {
        const list = this.props.list
        let order: "ASC" | "DESC" = "ASC"
        if (list.sort.orderBy === columnId) {
            if (list.sort.order === "ASC") {
                order = "DESC"
            } else {
                order = "ASC"
            }
        }

        list.setSort({orderBy: columnId, order})
        list.load()
        this.saveSettingsToLocalStorage()
    }

    @action
    private toggleColumns(list: string[]) {
        this.visibleColumnIds = list
        this.saveSettingsToLocalStorage()
    }

    @action
    private resetColumns() {
        this.visibleColumnIds = this.initialVisibleColumnIds
        this.saveSettingsToLocalStorage()
    }

    private getAllColumns(): DropdownItem[] {
        let items: DropdownItem[] = []
        for (let item of this.props.children) {
            let column: Column = (item as unknown) as Column
            items.push({
                title: column.props.header.props.title,
                value: column.props.id
            })
        }

        return items
    }
}
