import React, {Component} from "react"
import {SelectionModel} from "../SelectionModel"
import {observer} from "mobx-react"
import {Checkbox} from "../../forms/Checkbox/Checkbox"
import {IListItem} from "../../../../../lib/gate/interfaces"

interface Props {
    item: IListItem
    selectionModel: SelectionModel
}

@observer
export class CheckboxCell extends Component<Props, {}> {
    public render() {
        const isSelected = this.props.selectionModel.contains(this.props.item.id)
        return (
            <td className="table__cell">
                <div className="table__cell-wrapper">
                    <Checkbox isChecked={isSelected} onClick={this.onClick.bind(this)} />
                </div>
            </td>
        )
    }

    private onClick() {
        const id = this.props.item.id

        if (this.props.selectionModel.contains(id)) {
            this.props.selectionModel.remove([id])
        } else {
            this.props.selectionModel.add([id])
        }
    }
}
